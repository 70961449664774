var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'vms-pre-productions vd-padding-left-medium',
    _vm.isMediumScreen ? 'vms-pre-productions--small' : 'vms-content__container' ]},[(_vm.preProductionsLoading)?_c('default-loader',{staticClass:"vd-margin-top-extra-large"}):_vm._e(),(!_vm.preProductionsLoading && _vm.preProductions.length === 0)?_c('div',[(_vm.preProductionNewFormOpen)?_c('create-pre-production-form',{staticClass:"vd-margin-top-20"}):_vm._e(),_vm._m(0)],1):_vm._e(),(!_vm.preProductionsLoading && _vm.preProductions.length > 0)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeSidebar),expression:"closeSidebar"}],class:[
      'vd-border-grey vms-pre-productions__sidebar vd-padding-right-medium vd-background-white',
      _vm.isMediumScreen ? 'vms-pre-productions__sidebar--small' : '',
      _vm.isSidebarOpen ? 'vms-pre-productions__sidebar--open' : '' ],attrs:{"id":"togglePreProductionSidebarDiv"},on:{"click":_vm.toggleSidebar}},[_c('div',{staticClass:"vd-margin-top-20"},_vm._l((_vm.preProductions),function(preProduction,index){return _c('div',{key:preProduction.id,class:[
          'vms-pre-productions__sidebar--menu vd-margin-bottom-20 vd-padding-medium vd-padding-left-20',
          _vm.isTabActive(preProduction.type)
            ? 'vms-pre-productions__sidebar--menu__active vd-background-blue vd-white vd-btn-blue'
            : 'vd-black vd-background-lightest-grey',
          _vm.isMediumScreen
            ? 'vms-pre-productions__sidebar--menu__small'
            : 'vd-border-radius-25' ],on:{"click":function($event){$event.stopPropagation();return _vm.setActiveTab(index)}}},[_c('div',{staticClass:"row vms-pre-productions__sidebar--menu__content",attrs:{"id":((_vm.sidebarTypeIdName(preProduction.type)) + "Div")}},[_c('div',{staticClass:"vd-padding-left-small vd-padding-right-small"},[_c('i',{class:[
                'vms-pre-productions__types--icon vd-icon',
                _vm.iconClass(preProduction.type) ]})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSidebarOpen || !_vm.isMediumScreen),expression:"isSidebarOpen || !isMediumScreen"}],staticClass:"vms-pre-productions__types--name vd-margin-right-small",attrs:{"id":((_vm.sidebarTypeIdName(preProduction.type)) + "LabelSpan")}},[_vm._v(" "+_vm._s(preProduction.type)+" ")]),_c('span',{class:[
              'vms-pre-productions__types--count vd-border-radius-100 vd-text-align-center vd-display-inline-block vd-margin-right-extra-small',
              _vm.isTabActive(preProduction.type)
                ? 'vd-background-blue'
                : 'vd-background-lightest-grey' ],attrs:{"id":((_vm.sidebarTypeIdName(preProduction.type)) + "CountSpan")}},[_vm._v(" "+_vm._s(preProduction.items.length)+" ")])])])}),0)]):_vm._e(),(!_vm.preProductionsLoading && _vm.preProductions.length > 0)?_c('div',{class:[
      'vms-pre-productions__content',
      _vm.isMobileScreen ? 'vms-pre-productions__content--mobile' : '',
      _vm.isMediumScreen ? 'vms-pre-productions__content--small' : '' ],attrs:{"id":"preProductionsContainer"}},[_c('div',{staticClass:"vms-pre-productions__wrapper"},[(_vm.preProductionNewFormOpen)?_c('create-pre-production-form'):_vm._e(),_c('div',{staticClass:"row no-gutters vd-padding-medium"},[_c('div',{staticClass:"col-12 vd-padding-top-extra-small"},[_c('i',{class:[
              'vms-pre-productions__types--icon vd-icon vd-align-middle',
              _vm.iconClass(_vm.preProductionActiveTabItems.type) ]}),_c('span',{staticClass:"vd-h3 vd-align-middle"},[_vm._v(" "+_vm._s(_vm.preProductionActiveTabItems.type)+" ["+_vm._s(_vm.totalDocuments)+"] ")]),_c('span',{staticClass:"vd-padding-left-small vd-h5 vd-a vd-align-middle",attrs:{"id":"approveAllPreProductionSpan"},on:{"click":_vm.handleApproveAll}},[_vm._v("Approve All")])])]),_c('div',{class:[
          'vms-pre-productions__list',
          _vm.isMediumScreen ? 'vms-pre-productions__list--small' : '' ]},_vm._l((_vm.preProductionActiveTabItems.items),function(preProductionItem){return _c('pre-productions-list',{key:preProductionItem.id,attrs:{"is-mobile-screen":_vm.isMediumScreen,"pre-production-item":preProductionItem,"selected-pre-production-icon":_vm.iconClass(preProductionItem.type)}})}),1)],1)]):_vm._e(),_c('div',{staticClass:"clearfix"}),(_vm.showFileUploadModal)?_c('file-upload-modal'):_vm._e(),(_vm.showFileVersionsModal)?_c('file-versions-modal'):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vms-pre-productions__empty vd-text-align-center"},[_c('img',{attrs:{"src":"/images/empty-states/empty-pre-prod.svg"}}),_c('p',[_vm._v("There are no Pre-Production documents for this project yet.")])])}]

export { render, staticRenderFns }