<template>
  <div>
    <div class="vd-field">
      <label for="">Attachments</label>
    </div>

    <default-loader
      v-if="isAttachmentsLoading"
      class="vd-padding-small vd-background-lighter-grey vd-border-radius-6 vd-margin-bottom-small"
    ></default-loader>

    <template v-else>
      <div
        v-if="!isOrderJobChangeDone"
        class="vd-background-white vd-padding-extra-small vd-margin-bottom-small vd-border-radius-6"
      >
        <div
          class="vms-uploader__wrapper vd-border-lighter-grey vd-border-radius-6 vd-padding-top-20 vd-padding-bottom-20"
        >
          <file-upload-component
            :api-url="attachmentApiUrl"
            :allow-multiple="true"
            label-idle="<div class='vd-dark-grey vms-uploader__text'>Drop your file here or click to <u class='vd-light-blue'>browse</u></div><div class='vd-grey'>Supports pdf, doc, xls and ppt documents. Maximum file size 2GB.</div>"
            @handle:add-file="handleAddFile"
            @handle:process-file="handleProcessFile"
          ></file-upload-component>
        </div>
      </div>

      <div v-if="hasJobChange && attachments" class="vd-block-small">
        <div
          v-for="(file, index) in attachments"
          :key="index"
          class="vd-margin-bottom-small vd-display-flex vd-align-center"
        >
          <a
            class="vd-a vd-margin-right-small"
            :href="file.url"
            target="_blank"
          >
            <span class="vd-light-blue">{{ file.name }}</span>
          </a>

          <span
            v-if="!isOrderJobChangeDone"
            class="vd-margin-left-auto vd-icon icon-xmark vd-icon-circle vd-background-lighter-grey vd-hover-background-light-grey vd-dark-grey vd-hover-black small"
            @click="handleDeleteAttachment(file.id)"
          ></span>
        </div>
      </div>

      <div
        v-else-if="isOrderJobChangeDone"
        class="vd-full-width vd-padding-medium vd-padding-bottom-extra-large vd-background-lighter-grey vd-dark-grey vd-border-radius-6"
      >
        No attachments
      </div>
    </template>
  </div>
</template>

<script>
import FileUploadComponent from '@components/file-upload/file-upload'
import DefaultLoader from '@components/loaders/default-loader'

export default {
  components: {
    FileUploadComponent,
    DefaultLoader,
  },
  props: {
    attachmentApiUrl: {
      type: String,
      default: '',
      required: true,
    },

    isAttachmentsLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    isOrderJobChangeDone: {
      type: Boolean,
      required: false,
      default: false,
    },

    hasJobChange: {
      type: Boolean,
      required: false,
      default: false,
    },

    attachments: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  methods: {
    handleAddFile() {
      this.$emit('click:add-file')
    },

    handleProcessFile() {
      this.$emit('update:attachments')
    },

    handleDeleteAttachment(id) {
      this.$emit('click:delete-attachment', id)
    },
  },
}
</script>
