<template>
  <div
    :class="[
      'vms-pre-productions vd-padding-left-medium',
      isMediumScreen ? 'vms-pre-productions--small' : 'vms-content__container',
    ]"
  >
    <default-loader
      v-if="preProductionsLoading"
      class="vd-margin-top-extra-large"
    ></default-loader>

    <div v-if="!preProductionsLoading && preProductions.length === 0">
      <create-pre-production-form
        v-if="preProductionNewFormOpen"
        class="vd-margin-top-20"
      ></create-pre-production-form>

      <div class="vms-pre-productions__empty vd-text-align-center">
        <img src="/images/empty-states/empty-pre-prod.svg" />

        <p>There are no Pre-Production documents for this project yet.</p>
      </div>
    </div>

    <div
      v-if="!preProductionsLoading && preProductions.length > 0"
      id="togglePreProductionSidebarDiv"
      v-click-outside="closeSidebar"
      :class="[
        'vd-border-grey vms-pre-productions__sidebar vd-padding-right-medium vd-background-white',
        isMediumScreen ? 'vms-pre-productions__sidebar--small' : '',
        isSidebarOpen ? 'vms-pre-productions__sidebar--open' : '',
      ]"
      @click="toggleSidebar"
    >
      <div class="vd-margin-top-20">
        <div
          v-for="(preProduction, index) in preProductions"
          :key="preProduction.id"
          :class="[
            'vms-pre-productions__sidebar--menu vd-margin-bottom-20 vd-padding-medium vd-padding-left-20',
            isTabActive(preProduction.type)
              ? 'vms-pre-productions__sidebar--menu__active vd-background-blue vd-white vd-btn-blue'
              : 'vd-black vd-background-lightest-grey',
            isMediumScreen
              ? 'vms-pre-productions__sidebar--menu__small'
              : 'vd-border-radius-25',
          ]"
          @click.stop="setActiveTab(index)"
        >
          <div
            :id="`${sidebarTypeIdName(preProduction.type)}Div`"
            class="row vms-pre-productions__sidebar--menu__content"
          >
            <div class="vd-padding-left-small vd-padding-right-small">
              <i
                :class="[
                  'vms-pre-productions__types--icon vd-icon',
                  iconClass(preProduction.type),
                ]"
              />
            </div>

            <span
              v-show="isSidebarOpen || !isMediumScreen"
              :id="`${sidebarTypeIdName(preProduction.type)}LabelSpan`"
              class="vms-pre-productions__types--name vd-margin-right-small"
            >
              {{ preProduction.type }}
            </span>

            <span
              :id="`${sidebarTypeIdName(preProduction.type)}CountSpan`"
              :class="[
                'vms-pre-productions__types--count vd-border-radius-100 vd-text-align-center vd-display-inline-block vd-margin-right-extra-small',
                isTabActive(preProduction.type)
                  ? 'vd-background-blue'
                  : 'vd-background-lightest-grey',
              ]"
            >
              {{ preProduction.items.length }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!preProductionsLoading && preProductions.length > 0"
      id="preProductionsContainer"
      :class="[
        'vms-pre-productions__content',
        isMobileScreen ? 'vms-pre-productions__content--mobile' : '',
        isMediumScreen ? 'vms-pre-productions__content--small' : '',
      ]"
    >
      <div class="vms-pre-productions__wrapper">
        <create-pre-production-form
          v-if="preProductionNewFormOpen"
        ></create-pre-production-form>

        <div class="row no-gutters vd-padding-medium">
          <div class="col-12 vd-padding-top-extra-small">
            <i
              :class="[
                'vms-pre-productions__types--icon vd-icon vd-align-middle',
                iconClass(preProductionActiveTabItems.type),
              ]"
            />
            <span class="vd-h3 vd-align-middle">
              {{ preProductionActiveTabItems.type }}
              [{{ totalDocuments }}]
            </span>
            <span
              id="approveAllPreProductionSpan"
              class="vd-padding-left-small vd-h5 vd-a vd-align-middle"
              @click="handleApproveAll"
              >Approve All</span
            >
          </div>
        </div>

        <div
          :class="[
            'vms-pre-productions__list',
            isMediumScreen ? 'vms-pre-productions__list--small' : '',
          ]"
        >
          <pre-productions-list
            v-for="preProductionItem in preProductionActiveTabItems.items"
            :key="preProductionItem.id"
            :is-mobile-screen="isMediumScreen"
            :pre-production-item="preProductionItem"
            :selected-pre-production-icon="iconClass(preProductionItem.type)"
          ></pre-productions-list>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>

    <file-upload-modal v-if="showFileUploadModal"></file-upload-modal>
    <file-versions-modal v-if="showFileVersionsModal"></file-versions-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { kebabCase, camelCase, startCase } from 'lodash'
import PreProductionsList from '@views/project/pre-production/project-pre-production-list'
import CreatePreProductionForm from '@views/project/components/forms/pre-production/pre-production-form'
import SweetAlert from '@plugins/sweet-alert'
import ClickOutside from 'vue-click-outside'
import DefaultLoader from '@components/loaders/default-loader'
import { map } from 'lodash'
import FileUploadModal from '@views/project/pre-production/components/file-upload-modal'
import FileVersionsModal from '@views/project/pre-production/components/file-versions-modal'

export default {
  directives: {
    ClickOutside,
  },

  components: {
    PreProductionsList,
    CreatePreProductionForm,
    DefaultLoader,
    FileUploadModal,
    FileVersionsModal,
  },

  provide() {
    return {
      formActionType: 'create',
      selectedProductionTypeIcon: () => {
        return this.selectedPreProductionType
          ? this.iconClass(
              this.selectedPreProductionType.product_element_type.name
            )
          : 'icon-icon-paper-quote-right'
      },
    }
  },

  data() {
    return {
      isSidebarOpen: false,
    }
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      preProductionsLoading: 'project/pre-production/preProductionsLoading',
      preProductions: 'project/pre-production/preProductions',
      preProductionActiveTabItems:
        'project/pre-production/preProductionActiveTabItems',
      preProductionNewFormOpen:
        'project/pre-production/preProductionNewFormOpen',
      preProductionEditFormOpen:
        'project/pre-production/preProductionEditFormOpen',
      selectedPreProductionType:
        'project/pre-production/selectedPreProductionType',
      selectedPreProduction: 'project/pre-production/selectedPreProduction',
      preProductionActiveTabIndex:
        'project/pre-production/preProductionActiveTabIndex',
      showFileUploadModal: 'project/pre-production/showFileUploadModal',
      showFileVersionsModal: 'project/pre-production/showFileVersionsModal',
      isMobileScreen: 'common/isMobileScreen',
      isMediumScreen: 'common/isMediumScreen',
      isPreProductionChangesFormOpen:
        'project/pre-production/isPreProductionChangesFormOpen',
    }),

    totalDocuments() {
      return this.preProductionActiveTabItems &&
        this.preProductionActiveTabItems.items
        ? this.preProductionActiveTabItems.items.length
        : 0
    },
  },

  watch: {
    isMediumScreen(newVal) {
      this.isSidebarOpen = !newVal
    },
  },

  beforeRouteLeave(to, from, next) {
    const vm = this

    if (
      vm.preProductionNewFormOpen ||
      vm.preProductionEditFormOpen ||
      vm.isPreProductionChangesFormOpen
    ) {
      SweetAlert.fire({
        title: 'Confirm Navigation',
        html: `<br> You have unsaved changes. <br><br> Are you sure you want to leave this page?`,
        showCancelButton: true,
        confirmButtonText: 'Leave this page',
      }).then((result) => {
        if (result.isConfirmed) {
          vm.resetForms()
          next()
        }
      })
    } else {
      next()
    }
  },

  methods: {
    ...mapActions({
      updatePreProductionActiveTabIndex:
        'project/pre-production/updatePreProductionActiveTabIndex',
      updateSelectedPreProductionType:
        'project/pre-production/updateSelectedPreProductionType',
      showAddNewPreProductionsForm:
        'project/pre-production/showAddNewPreProductionsForm',
      showEditPreProductionsForm:
        'project/pre-production/showEditPreProductionsForm',
      updateSelectedPreProductionId:
        'project/pre-production/updateSelectedPreProductionId',
      approveAllPreProductions:
        'project/pre-production/approveAllPreProductions',
      resetPreProductionChanges:
        'project/pre-production/resetPreProductionChanges',
    }),

    sidebarTypeIdName(tabTypeName) {
      return `viewPreProduction${startCase(camelCase(tabTypeName)).replace(
        / /g,
        ''
      )}`
    },

    isTabActive(tabTypeName) {
      return (
        kebabCase(this.preProductionActiveTabItems?.type) ===
        kebabCase(tabTypeName)
      )
    },

    setActiveTab(preProductionIndex) {
      if (
        this.preProductionNewFormOpen ||
        this.preProductionEditFormOpen ||
        this.isPreProductionChangesFormOpen
      ) {
        SweetAlert.fire({
          title: 'Unsaved Changes',
          html: `<br> You have unsaved changes in progress. <br><br> Do you wish to discard them?`,
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        }).then((result) => {
          if (result.isConfirmed) {
            this.resetForms()
            this.updatePreProductionActiveTabIndex(preProductionIndex)
          }
        })
      } else {
        this.updatePreProductionActiveTabIndex(preProductionIndex)
        this.resetPreProductionChanges()
      }
    },

    resetForms() {
      if (this.preProductionEditFormOpen) {
        this.showEditPreProductionsForm({
          show: false,
        })
      } else {
        this.showAddNewPreProductionsForm(false)
      }

      this.updateSelectedPreProductionType(null)
      this.updateSelectedPreProductionId(null)
      this.resetPreProductionChanges()
    },

    handleApproveAll() {
      const preProductionItemsIds = map(
        this.preProductionActiveTabItems.items,
        'id'
      )

      SweetAlert.fire({
        title: 'Warning!',
        html: 'Are you sure you want to approve all documents?',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      }).then((result) => {
        if (result.isConfirmed) {
          this.approveAllPreProductions({
            project_id: this.project.id,
            includes: null,
            name: this.selectedPreProductionType.name,
            payload: {
              order_job_ids: preProductionItemsIds,
            },
          })
        }
      })
    },

    iconClass(preProductionType) {
      const preProductionIconTypes = {
        'Call Sheet': 'icon-paper-phone',
        Storyboard: 'icon-paper-windows',
        'Shot List': 'icon-paper-video',
        'Creative Concepts': 'icon-paper-eye',
        'Creative Treatment': 'icon-paper-paintbrush',
        'Location Sheet': 'icon-paper-pin',
        'Cast Sheet': 'icon-paper-person',
        'Script / Copy Writing': 'icon-paper-pencil',
        'Vision Board': 'icon-paper-lightbulb',
        'Voiceover Script': 'icon-paper-quote-right',
        'Style Frames': 'icon-paper-play',
        'Full Design Set': 'icon-paper-paint-roller',
      }

      let iconClass = !preProductionIconTypes[preProductionType]
        ? 'icon-paper-quote-right'
        : preProductionIconTypes[preProductionType]

      return iconClass
    },

    closeSidebar() {
      this.isSidebarOpen = false
    },

    toggleSidebar() {
      this.isSidebarOpen = this.isMediumScreen ? !this.isSidebarOpen : false
    },
  },
}
</script>

<style lang="scss">
@import '@styles/views/project/pre-productions';
</style>
