import { render, staticRenderFns } from "./pre-production-actions-dropdown.vue?vue&type=template&id=ed13afe8&"
import script from "./pre-production-actions-dropdown.vue?vue&type=script&lang=js&"
export * from "./pre-production-actions-dropdown.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports