<template>
  <div
    :id="`preProductionsList-${preProductionItem.order_job_id}`"
    :class="[
      'vms-pre-productions__list--container',
      hasOpenForm ? 'vms-pre-productions__list--container__edit' : '',
    ]"
  >
    <div
      :class="[
        'vd-margin-top-medium vd-position-relative',
        isExtraSmallScreen
          ? 'vd-margin-inline-medium'
          : 'vd-margin-left-medium',
      ]"
    >
      <div class="vd-padding-bottom-medium">
        <div class="vd-border-lighter-grey vd-border-width-1">
          <a
            :href="preProductionItem.file_url"
            target="_blank"
            rel="noopener"
            :title="preProductionItem.name"
            class="vms-pre-productions__list--link"
          >
            <img
              class="vms-pre-productions__list--thumbnail"
              src="/images/project/google-doc.png"
              :alt="preProductionItem.name"
            />
          </a>

          <text-popover
            tooltip-icon="vms-pre-productions__list--notes vd-icon-doc"
            tooltip-trigger="click blur"
            :tooltip-text="preProductionItem.external_note"
            :popover-custom-class="externalNotePopoverClass"
            :tooltip-id="`preProductionsExternalNoteHover-${preProductionItem.order_job_id}`"
          ></text-popover>

          <div class="row no-gutters">
            <div class="col-10">
              <a
                :href="preProductionItem.file_url"
                target="_blank"
                rel="noopener"
                :title="preProductionItem.name"
                class="vms-pre-productions__list--link"
              >
                <div class="vd-padding-small">
                  <h6 class="vd-black">
                    <img src="/images/project/doc-icon.png" />
                    <span class="vd-margin-left-extra-small">{{
                      preProductionItem.name | truncateString(34)
                    }}</span>
                  </h6>
                  <div
                    v-if="preProductionItem.production_date"
                    class="vd-dark-grey vd-h5"
                  >
                    Prod. Date: {{ isOnHold ? '(TBC)' : '' }}
                    {{
                      preProductionItem.production_date
                        | unixToFormat('DD MMM YYYY')
                    }}
                  </div>
                  <div
                    v-if="preProductionItem.duration"
                    class="vd-dark-grey vd-h5"
                  >
                    Duration: {{ isOnHold ? '(TBC)' : '' }}
                    {{ durationLabel }}
                  </div>
                </div>
              </a>
            </div>

            <div class="vms-pre-productions__actions--container col-2">
              <div
                id="toggleActionsDropdownDiv"
                v-click-outside="closeActionsDropdown"
                @click="toggleActionsDropdown"
              >
                <span
                  class="vms-pre-productions__actions vd-icon-three-dot"
                ></span>
                <pre-production-actions-dropdown
                  v-if="showActionsDropdown"
                  :pre-production-item="preProductionItem"
                ></pre-production-actions-dropdown>
              </div>
            </div>
          </div>
        </div>

        <pre-production-button-states
          :pre-production-item="preProductionItem"
        ></pre-production-button-states>
      </div>
    </div>

    <edit-pre-production-form
      v-if="isEditFormVisible"
      class="vms-pre-productions__list--container__edit--form"
    ></edit-pre-production-form>

    <pre-production-changes-form
      v-if="isPreProdutionChangesFormVisible"
    ></pre-production-changes-form>

    <pre-production-changes-list
      v-if="isPreProductionChangesListVisible"
    ></pre-production-changes-list>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FiltersMixin from '@mixins/filters-mixin'
import PreProductionActionsDropdown from '@views/project/pre-production/components/pre-production-actions-dropdown'
import EditPreProductionForm from '@views/project/components/forms/pre-production/pre-production-form'
import TextPopover from '@components/tooltips/text-popover'
import PreProductionButtonStates from '@views/project/pre-production/components/pre-production-button-states'
import ClickOutside from 'vue-click-outside'
import VmsConstants from '@configs/vms-constants'
import PreProductionChangesList from '@views/project/components/forms/pre-production/pre-production-changes/pre-production-changes-list.vue'
import PreProductionChangesForm from '@views/project/components/forms/pre-production/pre-production-changes/pre-production-changes-form.vue'
import DurationsConstants from '@configs/all-durations'

export default {
  directives: {
    ClickOutside,
  },

  components: {
    PreProductionActionsDropdown,
    EditPreProductionForm,
    TextPopover,
    PreProductionButtonStates,
    PreProductionChangesList,
    PreProductionChangesForm,
  },

  mixins: [FiltersMixin],

  props: {
    preProductionItem: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },

    selectedPreProductionIcon: {
      type: String,
      required: true,
      default: 'icon-paper-quote-right',
    },
  },

  provide() {
    return {
      formActionType: 'edit',
      selectedProductionTypeIcon: () => {
        return this.selectedPreProductionIcon
      },
    }
  },

  data() {
    return {
      showActionsDropdown: false,
      durationList: DurationsConstants.durations,
    }
  },

  computed: {
    ...mapGetters({
      preProductionEditFormOpen:
        'project/pre-production/preProductionEditFormOpen',
      selectedPreProduction: 'project/pre-production/selectedPreProduction',
      preProductionItemLoading:
        'project/pre-production/preProductionItemLoading',
      windowWidth: 'common/windowWidth',
      isPreProductionChangesListOpen:
        'project/pre-production/isPreProductionChangesListOpen',
      isPreProductionChangesFormOpen:
        'project/pre-production/isPreProductionChangesFormOpen',
      preProductionChangesSelectedPreProductionId:
        'project/pre-production/preProductionChangesSelectedPreProductionId',
    }),

    isEditFormVisible() {
      return (
        this.preProductionEditFormOpen &&
        this.selectedPreProduction?.order_job_id ===
          this.preProductionItem.order_job_id
      )
    },

    isPreProductionItemLoading() {
      return (
        this.preProductionItemLoading &&
        this.selectedPreProduction?.order_job_id ===
          this.preProductionItem.order_job_id
      )
    },

    isExtraSmallScreen() {
      return this.windowWidth < 480
    },

    isOnHold() {
      return (
        this.preProductionItem.status_id ===
        VmsConstants.orderJobStatuses.ORDER_JOB_ON_HOLD
      )
    },

    externalNotePopoverClass() {
      if (this.isExtraSmallScreen) {
        return 'vms-project__popover vms-project__popover--small'
      }
      return 'vms-project__popover'
    },

    isPreProdutionChangesFormVisible() {
      return (
        this.isPreProductionChangesFormOpen &&
        this.preProductionChangesSelectedPreProductionId ===
          this.preProductionItem.order_job_id
      )
    },

    isPreProductionChangesListVisible() {
      return (
        this.isPreProductionChangesListOpen &&
        this.preProductionChangesSelectedPreProductionId ===
          this.preProductionItem.order_job_id
      )
    },

    hasOpenForm() {
      return (
        this.isEditFormVisible ||
        this.isPreProdutionChangesFormVisible ||
        this.isPreProductionChangesListVisible
      )
    },

    durationLabel() {
      return this.durationList[this.preProductionItem.duration]
    },
  },

  methods: {
    toggleActionsDropdown() {
      this.showActionsDropdown = !this.showActionsDropdown
    },

    closeActionsDropdown() {
      this.showActionsDropdown = false
    },
  },
}
</script>
