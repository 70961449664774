<template>
  <div
    class="vms-dropdown vms-pre-productions__actions--dropdown vd-box-shadow vd-background-white vd-border-radius-6"
  >
    <div class="vd-h4">
      <button
        :class="[
          'vms-pre-productions__actions--dropdown-edit vd-padding-top-small vd-padding-bottom-extra-small vd-padding-left-medium vd-padding-right-medium vd-background-transparent vd-text-align-left',
          isEditUploadDisabled
            ? 'vd-background-lighter-grey vd-dark-grey'
            : 'vms-dropdown__list vms-pre-productions__actions--dropdown-list',
        ]"
        :disabled="isEditUploadDisabled"
        @click="handleEditDocument()"
      >
        Update details
      </button>

      <a
        class="vms-dropdown__list vms-pre-productions__actions--dropdown-view vd-black vms-pre-productions__actions--dropdown-list vd-padding-top-small vd-padding-bottom-extra-small vd-padding-left-medium vd-padding-right-medium"
        :href="preProductionFileUrl"
        @click.prevent="viewDocument"
      >
        View
      </a>

      <div
        v-if="preProductionItem.is_approved"
        class="vms-dropdown__list vms-pre-productions__actions--dropdown-unapprove vd-padding-top-small vd-padding-bottom-extra-small vd-padding-left-medium vd-padding-right-medium"
        @click="unapproveDocument"
      >
        Unapprove
      </div>

      <a
        v-else
        class="vms-dropdown__list vms-pre-productions__actions--dropdown-send vd-black vms-pre-productions__actions--dropdown-list vd-padding-top-small vd-padding-bottom-extra-small vd-padding-left-medium vd-padding-right-medium"
        :href="sendDocumentUrl"
        @click.prevent="sendPreProduction"
      >
        Send
      </a>

      <button
        :class="[
          'vms-pre-productions__actions--dropdown-notes vd-full-width vd-padding-top-small vd-padding-bottom-extra-small vd-padding-inline-medium vd-background-transparent vd-text-align-left vms-dropdown__list vms-pre-productions__actions--dropdown-list',
        ]"
        @click="handleEodNotesClick"
      >
        Notes
      </button>

      <button
        :class="[
          'vms-pre-productions__actions--dropdown-hold vd-full-width vd-padding-top-small vd-padding-bottom-extra-small vd-padding-inline-medium vd-background-transparent vd-text-align-left vms-dropdown__list vms-pre-productions__actions--dropdown-list',
        ]"
        @click="handleHoldUnholdClick"
      >
        {{ isPreProductionOnHold ? 'Take off hold' : 'Put on hold' }}
      </button>

      <button
        :class="[
          'vms-pre-productions__actions--dropdown-upload vd-padding-top-small vd-padding-bottom-extra-small vd-padding-left-medium vd-padding-right-medium vd-background-transparent vd-text-align-left',
          isEditUploadDisabled
            ? 'vd-background-lighter-grey vd-dark-grey'
            : 'vms-dropdown__list vms-pre-productions__actions--dropdown-list',
        ]"
        :disabled="isEditUploadDisabled"
        @click="handleOpenChanges"
      >
        Changes
      </button>

      <button
        :class="[
          'vms-pre-productions__actions--dropdown-upload vd-padding-top-small vd-padding-bottom-extra-small vd-padding-left-medium vd-padding-right-medium vd-background-transparent vd-text-align-left',
          isEditUploadDisabled
            ? 'vd-background-lighter-grey vd-dark-grey'
            : 'vms-dropdown__list vms-pre-productions__actions--dropdown-list',
        ]"
        :disabled="isEditUploadDisabled"
        @click="openFileUploadModal"
      >
        Upload
      </button>

      <button
        :class="[
          'vms-pre-productions__actions--dropdown-upload vd-padding-top-small vd-padding-bottom-extra-small vd-padding-left-medium vd-padding-right-medium vd-background-transparent vd-text-align-left',
          isEditUploadDisabled
            ? 'vd-background-lighter-grey vd-dark-grey'
            : 'vms-dropdown__list vms-pre-productions__actions--dropdown-list',
        ]"
        :disabled="isEditUploadDisabled"
        @click="openFileVersionsModal"
      >
        Previous versions
      </button>

      <div
        v-if="isDeleteOptionVisible"
        class="vms-dropdown__list vms-pre-productions__actions--dropdown-delete vd-padding-top-small vd-padding-bottom-extra-small vd-padding-left-medium vd-padding-right-medium"
        @click="deleteDocument"
      >
        <span>Delete</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SweetAlert from '@plugins/sweet-alert'
import momentTz from 'moment-timezone'
import AppConfig from '@configs/app-config'
import ApiCustomIncludes from '@configs/api-custom-includes'
import VmsConstants from '@configs/vms-constants'

export default {
  props: {
    preProductionItem: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      preProductionNewFormOpen:
        'project/pre-production/preProductionNewFormOpen',
      preProductionEditFormOpen:
        'project/pre-production/preProductionEditFormOpen',
      selectedPreProduction: 'project/pre-production/selectedPreProduction',
      isProjectDraft: 'project/isProjectDraft',
      showFileUploadModal: 'project/pre-production/showFileUploadModal',
      isPreProductionChangesFormOpen:
        'project/pre-production/isPreProductionChangesFormOpen',
      preProductionChangesSelectedPreProductionId:
        'project/pre-production/preProductionChangesSelectedPreProductionId',
      isProjectOnHold: 'project/isProjectOnHold',
    }),

    sendDocumentUrl() {
      return this.isProjectDraft
        ? null
        : `${AppConfig.vmsApiUrl}/orders/view-pre-production-delivery-email/${this.preProductionItem.order_job_id}`
    },

    preProductionFileUrl() {
      return this.isProjectDraft ? null : this.preProductionItem.file_url
    },

    isEditUploadDisabled() {
      return (
        this.selectedPreProduction?.order_job_id ===
        this.preProductionItem.order_job_id
      )
    },

    isDeleteOptionVisible() {
      const productionDate = momentTz.unix(
        this.preProductionItem.production_date
      )
      const now = momentTz()

      return productionDate.isAfter(now) && !this.preProductionItem.is_approved
    },

    isPreProductionOnHold() {
      return (
        this.preProductionItem.status_id ===
        VmsConstants.orderJobStatuses.ORDER_JOB_ON_HOLD
      )
    },
  },

  methods: {
    ...mapActions({
      showEditPreProductionsForm:
        'project/pre-production/showEditPreProductionsForm',
      updateSelectedPreProductionId:
        'project/pre-production/updateSelectedPreProductionId',
      approvePreProduction: 'project/pre-production/approvePreProduction',
      deletePreProduction: 'project/pre-production/deletePreProduction',
      showAddNewPreProductionsForm:
        'project/pre-production/showAddNewPreProductionsForm',
      sendActivateAndConfirmationEmail:
        'project/invoice/sendActivateAndConfirmationEmail',
      toggleFileUploadModalShow:
        'project/pre-production/toggleFileUploadModalShow',
      toggleFileVersionsModalShow:
        'project/pre-production/toggleFileVersionsModalShow',
      showPreProductionChangesList:
        'project/pre-production/showPreProductionChangesList',
      showPreProductionChangesForm:
        'project/pre-production/showPreProductionChangesForm',
      getPreProductionChanges: 'project/pre-production/getPreProductionChanges',
      setPreProductionChangesSelectedPreProductionId:
        'project/pre-production/setPreProductionChangesSelectedPreProductionId',
      holdPreProduction: 'project/pre-production/holdPreProduction',
      unholdPreProduction: 'project/pre-production/unholdPreProduction',
      setSelectedEodNoteTypeId: 'project/setSelectedEodNoteTypeId',
      toggleEodNotesModal: 'project/toggleEodNotesModal',
    }),

    displayDraftProjectAlert() {
      SweetAlert.fire({
        title: 'Draft Project',
        html: `<br> Please confirm & activate your project. <br><br> Once the project is confirmed the document will be generated and ready for use.`,
        showCancelButton: true,
        confirmButtonText: 'Confirm Project',
      }).then((result) => {
        if (result.isConfirmed) {
          this.sendActivateAndConfirmationEmail()
        }
      })
    },

    viewDocument() {
      if (this.project.has_draft_order || this.isProjectDraft) {
        this.displayDraftProjectAlert()
      } else {
        window.open(this.preProductionFileUrl, '_blank')
      }
    },

    sendPreProduction(event) {
      if (this.project.has_draft_order || this.isProjectDraft) {
        this.displayDraftProjectAlert()
      } else {
        // Check if cmd/ctrl key is selected
        if (event.metaKey || event.ctrlKey) {
          window.open(this.sendDocumentUrl, '_blank')
        } else {
          window.open(this.sendDocumentUrl, '_self')
        }
      }
    },

    unapproveDocument() {
      const vm = this

      this.updateSelectedPreProductionId(this.preProductionItem.id)

      SweetAlert.fire({
        title: 'Unapprove',
        html: `<br> Are you sure you want to unapprove this document: <br><br> <strong>${vm.preProductionItem.name}</strong>?`,
        showCancelButton: true,
        confirmButtonText: 'Unapprove',
      }).then((result) => {
        if (result.isConfirmed) {
          this.approvePreProduction({
            project_id: this.project.id,
            order_job_id: this.preProductionItem.order_job_id,
            includes: null,
            name: this.preProductionItem.name,
            approve: false,
          })
        } else {
          this.updateSelectedPreProductionId(null)
        }
      })
    },

    showProjectOnHoldWarning() {
      SweetAlert.fire({
        title: 'Project On Hold',
        html: `<br> Please take the project off hold before making changes on jobs.`,
        showCancelButton: false,
        confirmButtonText: 'Close',
      })
    },

    handleEditDocument() {
      if (this.isProjectOnHold) {
        this.showProjectOnHoldWarning()
      } else {
        if (
          (this.preProductionNewFormOpen || this.preProductionEditFormOpen) &&
          !this.isEditUploadDisabled
        ) {
          this.showWarningAlert()
        } else {
          this.openEditForm()
        }
      }
    },

    openEditForm() {
      this.showEditPreProductionsForm({
        show: true,
      })

      this.showAddNewPreProductionsForm(false)
      this.updateSelectedPreProductionId(this.preProductionItem.id)
    },

    openFileUploadModal() {
      if (this.project.has_draft_order || this.isProjectDraft) {
        this.displayDraftProjectAlert()
      } else {
        this.updateSelectedPreProductionId(this.preProductionItem.id)
        this.toggleFileUploadModalShow()
      }
    },

    openFileVersionsModal() {
      if (this.project.has_draft_order || this.isProjectDraft) {
        this.displayDraftProjectAlert()
      } else {
        this.updateSelectedPreProductionId(this.preProductionItem.id)
        this.toggleFileVersionsModalShow()
      }
    },

    showWarningAlert() {
      SweetAlert.fire({
        title: 'Unsaved Changes',
        html: `<br> You have unsaved changes in progress. <br><br>  Do you wish to discard these changes and open a new form?`,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      }).then((result) => {
        if (result.isConfirmed) {
          this.openEditForm()
        }
      })
    },

    deleteDocument() {
      const vm = this

      this.updateSelectedPreProductionId(this.preProductionItem.id)
      this.showEditPreProductionsForm({
        show: false,
      })

      SweetAlert.fire({
        title: 'Delete',
        html: `<br> Are you sure you want to delete this document: <br><br> <strong>${vm.preProductionItem.name}</strong>?`,
        showCancelButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: '#ff3333',
      }).then((result) => {
        if (result.isConfirmed) {
          this.deletePreProduction({
            project_id: this.project.id,
            order_job_id: this.preProductionItem.order_job_id,
            includes: null,
            name: this.preProductionItem.name,
          })
        } else {
          this.updateSelectedPreProductionId(null)
        }
      })
    },

    handleOpenChanges() {
      if (
        this.preProductionChangesSelectedPreProductionId !==
        this.preProductionItem.order_job_id
      ) {
        if (this.isPreProductionChangesFormOpen) {
          SweetAlert.fire({
            title: 'Warning!',
            html: 'Are you sure you want to cancel your changes?',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
          }).then((result) => {
            if (result.isConfirmed) {
              this.showPreProductionChangesForm(false)
              this.openPreProductionChangesList()
            }
          })
        } else {
          this.openPreProductionChangesList()
        }
      }
    },

    openPreProductionChangesList() {
      this.setPreProductionChangesSelectedPreProductionId(
        this.preProductionItem.order_job_id
      )
      this.showPreProductionChangesList(true)
      this.getPreProductionChanges({
        projectId: this.project.id,
        orderJobId: this.preProductionItem.order_job_id,
        includes: ApiCustomIncludes.preProductionChanges,
        payload: {
          sort: 'desc',
          sortBy: 'created_at',
        },
      })
    },

    handleHoldUnholdClick() {
      if (this.isProjectOnHold) {
        this.showProjectOnHoldWarning()
      } else {
        const payload = {
          project_id: this.project.id,
          order_job_id: this.preProductionItem.order_job_id,
          includes: null,
          name: this.preProductionItem.name,
        }

        if (this.isPreProductionOnHold) {
          this.unholdPreProduction(payload)
        } else {
          this.holdPreProduction(payload)
        }
      }
    },

    handleEodNotesClick() {
      this.setSelectedEodNoteTypeId(this.preProductionItem.order_job_id)
      this.toggleEodNotesModal(true)
    },
  },
}
</script>
