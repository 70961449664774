<template>
  <job-changes-list
    :is-job-changes-loading="isPreProductionChangesLoading"
    :job-changes-list="preProductionChangesList"
    :icon="icon"
    title="Pre-production changes"
    class="vms-pre-productions__list--container__edit--form"
    @click:close="handleClose"
    @click:add-job-change="handleAddJobChange"
    @click:view-job-change="handleViewJobChange"
    @click:mark-as-complete="handleMarkAsComplete"
    @click:delete-job-change="handleDeleteJobChange"
  ></job-changes-list>
</template>

<script>
import JobChangesList from '@views/project/components/forms/job-changes-form/job-changes-list'
import ApiCustomIncludes from '@configs/api-custom-includes'
import { mapGetters, mapActions } from 'vuex'
import momentTz from 'moment-timezone'

export default {
  components: {
    JobChangesList,
  },

  inject: ['selectedProductionTypeIcon'],

  computed: {
    ...mapGetters({
      isPreProductionChangesLoading:
        'project/pre-production/isPreProductionChangesLoading',
      preProductionChangesList:
        'project/pre-production/preProductionChangesList',
      preProductionChangesSelectedPreProductionId:
        'project/pre-production/preProductionChangesSelectedPreProductionId',
    }),

    icon() {
      return this.selectedProductionTypeIcon()
    },
  },

  methods: {
    ...mapActions({
      showPreProductionChangesForm:
        'project/pre-production/showPreProductionChangesForm',
      showPreProductionChangesList:
        'project/pre-production/showPreProductionChangesList',
      resetPreProductionChanges:
        'project/pre-production/resetPreProductionChanges',
      createPreProductionChange:
        'project/pre-production/createPreProductionChange',
      setSelectedPreProductionChange:
        'project/pre-production/setSelectedPreProductionChange',
      deletePreProductionChange:
        'project/pre-production/deletePreProductionChange',
      updatePreProductionChange:
        'project/pre-production/updatePreProductionChange',
    }),

    handleAddJobChange() {
      this.createPreProductionChange({
        projectId: this.$route.params.projectId,
        orderJobId: this.preProductionChangesSelectedPreProductionId,
        includes: ApiCustomIncludes.preProductionChanges,
        payload: {
          sort: 'desc',
          sortBy: 'created_at',
        },
      })

      this.showPreProductionChangesForm(true)
      this.showPreProductionChangesList(false)
    },

    handleClose() {
      this.resetPreProductionChanges()
    },

    handleViewJobChange(jobChange) {
      this.setSelectedPreProductionChange(jobChange)
      this.showPreProductionChangesList(false)
      this.showPreProductionChangesForm(true)
    },

    handleMarkAsComplete(jobChange) {
      this.updatePreProductionChange({
        projectId: this.$route.params.projectId,
        orderJobId: jobChange.order_job_id,
        orderJobChangeId: jobChange.id,
        includes: ApiCustomIncludes.preProductionChanges,
        payload: {
          date: momentTz.unix(jobChange.event.from).format('YYYY-MM-DD'),
          time: momentTz.unix(jobChange.event.from).format('hh:mm:ss'),
          time_required: String(jobChange.time_required),
          state_id: jobChange.event.state_id,
          is_done: true,
          sort: 'desc',
          sortBy: 'created_at',
        },
      })
    },

    handleDeleteJobChange(jobChange) {
      this.deletePreProductionChange({
        projectId: this.$route.params.projectId,
        orderJobId: jobChange.order_job_id,
        orderJobChangeId: jobChange.id,
        includes: ApiCustomIncludes.preProductionChanges,
        payload: {
          sort: 'desc',
          sortBy: 'created_at',
        },
      })
    },
  },
}
</script>
