<template>
  <job-changes-form
    :users-list="preProductionUsers"
    :attachment-api-url="attachmentApiUrl"
    :job-change="selectedPreProductionChange"
    :is-loading="isPreProductionChangesLoading"
    :is-users-list-loading="isPreProductionUsersLoading"
    :is-attachments-loading="isPreProductionChangeAttachmentsLoading"
    :icon="icon"
    title="Add pre-production change"
    class="vms-pre-productions__list--container__edit--form"
    @click:save="handleSave"
    @click:cancel="handleCancel"
    @click:delete-attachment="handleDeleteAttachment"
    @update:attachments="getAttachments"
  ></job-changes-form>
</template>

<script>
import JobChangesForm from '@views/project/components/forms/job-changes-form/job-changes-form'
import { mapGetters, mapActions } from 'vuex'
import ApiCustomIncludes from '@configs/api-custom-includes'
import AppConfig from '@configs/app-config'
import { isEmpty } from 'lodash'

export default {
  components: {
    JobChangesForm,
  },

  inject: ['selectedProductionTypeIcon'],

  computed: {
    ...mapGetters({
      isPreProductionChangesLoading:
        'project/pre-production/isPreProductionChangesLoading',
      selectedPreProductionChange:
        'project/pre-production/selectedPreProductionChange',
      isPreProductionChangeAttachmentsLoading:
        'project/pre-production/isPreProductionChangeAttachmentsLoading',
      preProductionUsers: 'user/preProductionUsers',
      isPreProductionUsersLoading: 'user/isPreProductionUsersLoading',
    }),

    attachmentApiUrl() {
      const projectId = this.$route.params?.projectId
      const orderJobId = this.selectedPreProductionChange?.order_job_id
      const orderJobChangeId = this.selectedPreProductionChange?.id
      return `${AppConfig.vmsApiUrl}/api/v2/internal/projects/${projectId}/jobs/pre-production/${orderJobId}/changes/${orderJobChangeId}/attachments`
    },

    icon() {
      return this.selectedProductionTypeIcon()
    },
  },

  mounted() {
    this.getAttachments()
    this.getPreProductionUsers({
      payload: {
        sort: 'asc',
        sortBy: 'first_name',
      },
      includes: [],
    })
  },

  beforeDestroy() {
    // clear selected order job change
    this.setSelectedPreProductionChange(null)
    // clear users list
    this.setPreProductionUsers([])
  },

  methods: {
    ...mapActions({
      setPreProductionUsers: 'user/setPreProductionUsers',
      showPreProductionChangesForm:
        'project/pre-production/showPreProductionChangesForm',
      showPreProductionChangesList:
        'project/pre-production/showPreProductionChangesList',
      updatePreProductionChange:
        'project/pre-production/updatePreProductionChange',
      getPreProductionChangeAttachments:
        'project/pre-production/getPreProductionChangeAttachments',
      deletePreProductionChangeAttachment:
        'project/pre-production/deletePreProductionChangeAttachment',
      setSelectedPreProductionChange:
        'project/pre-production/setSelectedPreProductionChange',
      getPreProductionUsers: 'user/getPreProductionUsers',
    }),

    getAttachments() {
      if (!isEmpty(this.selectedPreProductionChange)) {
        this.getPreProductionChangeAttachments({
          projectId: this.$route.params.projectId,
          orderJobId: this.selectedPreProductionChange?.order_job_id,
          orderJobChangeId: this.selectedPreProductionChange?.id,
        })
      }
    },

    handleSave(payload) {
      this.updatePreProductionChange({
        projectId: this.$route.params?.projectId,
        orderJobId: this.selectedPreProductionChange?.order_job_id,
        orderJobChangeId: this.selectedPreProductionChange?.id,
        includes: ApiCustomIncludes.preProductionChanges,
        payload: payload,
      })
    },

    handleCancel() {
      this.showPreProductionChangesForm(false)
      this.showPreProductionChangesList(true)
    },

    handleDeleteAttachment(attachmentId) {
      this.deletePreProductionChangeAttachment({
        projectId: this.$route.params?.projectId,
        orderJobId: this.selectedPreProductionChange?.order_job_id,
        orderJobChangeId: this.selectedPreProductionChange?.id,
        attachmentId,
      })
    },
  },
}
</script>
