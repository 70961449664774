export default Object.freeze({
  durations: {
    15: '15 minutes',
    30: '30 minutes',
    45: '45 minutes',
    60: '1 hour',
    75: '1 hour and 15 mins',
    90: '1 hour and 30 mins',
    105: '1 hour and 45 mins',
    120: '2 hours',
    135: '2 hours and 15 mins',
    150: '2 hours and 30 mins',
    165: '2 hours and 45 mins',
    180: '3 hours',
    195: '3 hours and 15 mins',
    210: '3 hours and 30 mins',
    225: '3 hours and 45 mins',
    240: '4 hours',
    255: '4 hours and 15 mins',
    270: '4 hours and 30 mins',
    285: '4 hours and 45 mins',
    300: '5 hours',
    315: '5 hours and 15 mins',
    330: '5 hours and 30 mins',
    345: '5 hours and 45 mins',
    360: '6 hours',
    375: '6 hours and 15 mins',
    390: '6 hours and 30 mins',
    405: '6 hours and 45 mins',
    420: '7 hours',
    435: '7 hours and 15 mins',
    450: '7 hours and 30 mins',
    465: '7 hours and 45 mins',
    480: '8 hours',
    495: '8 hours and 15 mins',
    510: '8 hours and 30 mins',
    525: '8 hours and 45 mins',
    540: '9 hours',
    555: '9 hours and 15 mins',
    570: '9 hours and 30 mins',
    585: '9 hours and 45 mins',
    600: '10 hours',
    660: '11 hours',
    720: '12 hours',
    840: '14 hours',
    960: '16 hours',
    1440: '3 days',
    1920: '4 days',
    2400: '5 days',
    2880: '6 days',
    3360: '7 days',
  },
})
