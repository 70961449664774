<template>
  <div class="vd-margin-bottom-large vd-padding-small vd-margin-medium">
    <div
      :class="[
        'row vd-align-center vd-box-shadow vd-border-radius-10 vd-position-relative vd-padding-small',
        status.backgroundClass,
      ]"
    >
      <div class="col-12 col-xl-3">
        <div>{{ displayName }}</div>
        <small :class="status.textClass">{{ status.text }}</small>
      </div>
      <div class="col-12 col-xl-3">
        <div class="vd-display-flex vd-flex-nowrap">
          <user-profile
            :user="jobChange.editor_user || { full_name: '--' }"
            class="vd-margin-top-extra-small vd-margin-right-small d-none d-md-block"
          ></user-profile>
          <div>
            <div :class="['vd-dark-grey', columnClass]">Assigned to</div>
            <div v-if="jobChange.editor_user" :class="columnClass">
              {{ jobChange.editor_user.full_name }}
            </div>
            <div v-else>Unassigned</div>
          </div>
        </div>
      </div>
      <div class="ol-12 col-md-auto col-md-3 col-xl-2">
        <div
          :class="['vd-margin-right-small mr-md-0 vd-dark-grey', columnClass]"
        >
          Date
        </div>
        <div v-if="jobChange.event" :class="columnClass">
          {{ jobChange.event.from | unixToFormat('DD/MM/YYYY') }}
        </div>
        <div v-else>--</div>
      </div>
      <div class="col-12 col-md-auto col-md-3 col-xl-2">
        <div
          :class="['vd-margin-right-small mr-md-0 vd-dark-grey', columnClass]"
        >
          Time required
        </div>
        <div v-if="jobChange.time_required" :class="columnClass">
          {{ timeRequired }}
        </div>
        <div v-else>--</div>
      </div>
      <div class="col-12 col-md-auto col-md-3 col-xl-2">
        <div :class="['vd-dark-grey', columnClass]">Start time</div>
        <div v-if="jobChange.event" :class="[columnClass]">
          {{ jobChange.event.from | unixToFormat('hh:mm A') }}
        </div>
        <div v-else>--</div>
      </div>

      <div
        :class="[
          'vms-job-changes-list__actions vd-position-absolute',
          isMobileScreen ? 'vms-job-changes-list__actions--mobile' : '',
        ]"
      >
        <job-changes-dropdown
          :can-edit="!jobChange.is_done"
          :can-mark-as-complete="!jobChange.is_done && !isChangesIncomplete"
          @click:view="handleView"
          @click:mark-as-complete="handleMarkAsComplete"
          @click:delete="handleDelete"
        ></job-changes-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import UserProfile from '@components/user-profile/user-profile'
import SweetAlert from '@plugins/sweet-alert'
import DurationsConstants from '@configs/all-durations'
import FiltersMixin from '@mixins/filters-mixin'
import JobChangesDropdown from '@views/project/components/forms/job-changes-form/components/job-changes-dropdown'
import { mapGetters } from 'vuex'

export default {
  components: {
    UserProfile,
    JobChangesDropdown,
  },

  mixins: [FiltersMixin],

  props: {
    jobChange: {
      type: Object,
      required: true,
    },

    displayName: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
    }),

    isChangesIncomplete() {
      return !this.jobChange.time_required || !this.jobChange.event?.from
    },

    timeRequired() {
      return DurationsConstants.durations[this.jobChange?.time_required] || ''
    },

    columnClass() {
      return this.isMobileScreen
        ? 'vd-display-inline-block vd-margin-right-small'
        : 'vd-display-block'
    },

    status() {
      if (this.jobChange.is_done) {
        return {
          text: 'Changes completed',
          backgroundClass: 'vd-background-light-green',
          textClass: 'vd-green',
        }
      }

      if (this.isChangesIncomplete) {
        return {
          text: 'Changes not scheduled',
          backgroundClass: 'vd-background-light-red',
          textClass: 'vd-red',
        }
      }

      return {
        text: 'Changes scheduled',
        backgroundClass: 'vd-background-white',
        textClass: 'vd-dark-grey',
      }
    },
  },

  methods: {
    handleView() {
      this.$emit('click:view-job-change', this.jobChange)
    },

    handleMarkAsComplete() {
      this.$emit('click:mark-as-complete', this.jobChange)
    },

    handleDelete() {
      SweetAlert.fire({
        title: 'Delete',
        html: 'Are you sure you want to delete this job?',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: '#ff3333',
      }).then((result) => {
        if (result.isConfirmed) {
          this.$emit('click:delete-job-change', this.jobChange)
        }
      })
    },
  },
}
</script>
