<template>
  <v-autocomplete
    :items="timeOptions"
    :menu-props="menuProps"
    :value="timeValue"
    persistent-placeholder
    item-text="text"
    item-value="value"
    no-data-text="No results to display"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:selection="{ item }">
      <span v-if="item">{{ item.text }}</span>
    </template>
  </v-autocomplete>
</template>

<script>
import momentTz from 'moment-timezone'
import Vuetify from 'vuetify'

export default {
  model: {
    prop: 'timeValue',
  },
  props: {
    timeValue: {
      type: String,
      required: false,
      default: null,
    },

    selectedDate: {
      type: String,
      required: false,
      default: '',
    },

    selectedDateFormat: {
      type: String,
      required: false,
      default: 'DD MMM YYYY',
    },

    timeInterval: {
      type: Number,
      required: false,
      default: 15,
    },
  },

  vuetify: new Vuetify(),

  data() {
    return {
      menuProps: {
        auto: true,
        closeOnClick: true,
        offsetY: true,
      },
    }
  },

  computed: {
    minTimeValue() {
      const now = momentTz()
      return momentTz(this.selectedDate, this.selectedDateFormat).isAfter(now)
        ? '00:00:00'
        : now
            .minutes(
              Math.ceil(now.minutes() / this.timeInterval) * this.timeInterval
            )
            .seconds(0)
            .format('HH:mm:ss')
    },

    timeOptions() {
      const timeOptions = []
      const maxEndTimeMinutes = 60 - this.timeInterval
      const endTime = momentTz(`23:${maxEndTimeMinutes}:00`, 'HH:mm:ss')
      const startTime = momentTz(this.minTimeValue, 'HH:mm:ss')

      while (startTime.isSameOrBefore(endTime)) {
        timeOptions.push({
          text: startTime.format('h:mm a'),
          value: startTime.format('HH:mm:ss'),
        })
        startTime.add(this.timeInterval, 'minutes')
      }

      return timeOptions
    },
  },

  watch: {
    minTimeValue(newMinTime) {
      const selectedTime = momentTz(this.timeValue, 'HH;mm:ss')
      const minTime = momentTz(newMinTime, 'HH:mm:ss')
      if (selectedTime.isBefore(minTime)) {
        this.$emit('input', null)
      }
    },
  },
}
</script>
