var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vms-dropdown vms-pre-productions__actions--dropdown vd-box-shadow vd-background-white vd-border-radius-6"},[_c('div',{staticClass:"vd-h4"},[_c('button',{class:[
        'vms-pre-productions__actions--dropdown-edit vd-padding-top-small vd-padding-bottom-extra-small vd-padding-left-medium vd-padding-right-medium vd-background-transparent vd-text-align-left',
        _vm.isEditUploadDisabled
          ? 'vd-background-lighter-grey vd-dark-grey'
          : 'vms-dropdown__list vms-pre-productions__actions--dropdown-list' ],attrs:{"disabled":_vm.isEditUploadDisabled},on:{"click":function($event){return _vm.handleEditDocument()}}},[_vm._v(" Update details ")]),_c('a',{staticClass:"vms-dropdown__list vms-pre-productions__actions--dropdown-view vd-black vms-pre-productions__actions--dropdown-list vd-padding-top-small vd-padding-bottom-extra-small vd-padding-left-medium vd-padding-right-medium",attrs:{"href":_vm.preProductionFileUrl},on:{"click":function($event){$event.preventDefault();return _vm.viewDocument($event)}}},[_vm._v(" View ")]),(_vm.preProductionItem.is_approved)?_c('div',{staticClass:"vms-dropdown__list vms-pre-productions__actions--dropdown-unapprove vd-padding-top-small vd-padding-bottom-extra-small vd-padding-left-medium vd-padding-right-medium",on:{"click":_vm.unapproveDocument}},[_vm._v(" Unapprove ")]):_c('a',{staticClass:"vms-dropdown__list vms-pre-productions__actions--dropdown-send vd-black vms-pre-productions__actions--dropdown-list vd-padding-top-small vd-padding-bottom-extra-small vd-padding-left-medium vd-padding-right-medium",attrs:{"href":_vm.sendDocumentUrl},on:{"click":function($event){$event.preventDefault();return _vm.sendPreProduction($event)}}},[_vm._v(" Send ")]),_c('button',{class:[
        'vms-pre-productions__actions--dropdown-notes vd-full-width vd-padding-top-small vd-padding-bottom-extra-small vd-padding-inline-medium vd-background-transparent vd-text-align-left vms-dropdown__list vms-pre-productions__actions--dropdown-list' ],on:{"click":_vm.handleEodNotesClick}},[_vm._v(" Notes ")]),_c('button',{class:[
        'vms-pre-productions__actions--dropdown-hold vd-full-width vd-padding-top-small vd-padding-bottom-extra-small vd-padding-inline-medium vd-background-transparent vd-text-align-left vms-dropdown__list vms-pre-productions__actions--dropdown-list' ],on:{"click":_vm.handleHoldUnholdClick}},[_vm._v(" "+_vm._s(_vm.isPreProductionOnHold ? 'Take off hold' : 'Put on hold')+" ")]),_c('button',{class:[
        'vms-pre-productions__actions--dropdown-upload vd-padding-top-small vd-padding-bottom-extra-small vd-padding-left-medium vd-padding-right-medium vd-background-transparent vd-text-align-left',
        _vm.isEditUploadDisabled
          ? 'vd-background-lighter-grey vd-dark-grey'
          : 'vms-dropdown__list vms-pre-productions__actions--dropdown-list' ],attrs:{"disabled":_vm.isEditUploadDisabled},on:{"click":_vm.handleOpenChanges}},[_vm._v(" Changes ")]),_c('button',{class:[
        'vms-pre-productions__actions--dropdown-upload vd-padding-top-small vd-padding-bottom-extra-small vd-padding-left-medium vd-padding-right-medium vd-background-transparent vd-text-align-left',
        _vm.isEditUploadDisabled
          ? 'vd-background-lighter-grey vd-dark-grey'
          : 'vms-dropdown__list vms-pre-productions__actions--dropdown-list' ],attrs:{"disabled":_vm.isEditUploadDisabled},on:{"click":_vm.openFileUploadModal}},[_vm._v(" Upload ")]),_c('button',{class:[
        'vms-pre-productions__actions--dropdown-upload vd-padding-top-small vd-padding-bottom-extra-small vd-padding-left-medium vd-padding-right-medium vd-background-transparent vd-text-align-left',
        _vm.isEditUploadDisabled
          ? 'vd-background-lighter-grey vd-dark-grey'
          : 'vms-dropdown__list vms-pre-productions__actions--dropdown-list' ],attrs:{"disabled":_vm.isEditUploadDisabled},on:{"click":_vm.openFileVersionsModal}},[_vm._v(" Previous versions ")]),(_vm.isDeleteOptionVisible)?_c('div',{staticClass:"vms-dropdown__list vms-pre-productions__actions--dropdown-delete vd-padding-top-small vd-padding-bottom-extra-small vd-padding-left-medium vd-padding-right-medium",on:{"click":_vm.deleteDocument}},[_c('span',[_vm._v("Delete")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }