<template>
  <div
    class="vms-project-files__dropdown vd-position-absolute vd-background-white vd-box-shadow vd-background-white vd-border-radius-6"
  >
    <div>
      <a
        class="vms-dropdown__list vms-project-files__dropdown-list vms-project-files__action--view vd-cursor-pointer vd-padding-top-small vd-padding-bottom-small vd-padding-left-medium vd-padding-right-medium vd-black vd-text-align-left"
        :href="preProductionFileUrl"
        @click.prevent="viewDocument"
      >
        View
      </a>

      <a
        class="vms-dropdown__list vms-project-files__dropdown-list vms-project-files__action--delete vd-cursor-pointer vd-padding-top-small vd-padding-bottom-small vd-padding-left-medium vd-padding-right-medium vd-black vd-text-align-left"
        @click.prevent="handleRestoreFileVersion"
      >
        Restore
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    preProductionFileVersion: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      selectedPreProduction: 'project/pre-production/selectedPreProduction',
    }),

    preProductionFileUrl() {
      return this.preProductionFileVersion.url
    },
  },

  methods: {
    ...mapActions({
      toggleFileVersionsModalShow:
        'project/pre-production/toggleFileVersionsModalShow',
      restorePreProductionFileVersion:
        'project/pre-production/restorePreProductionFileVersion',
    }),

    viewDocument() {
      window.open(this.preProductionFileUrl, '_blank')
    },

    handleRestoreFileVersion() {
      // Send api call to initiate file upload to google drive.
      this.restorePreProductionFileVersion({
        project_id: this.project.id,
        order_job_id: this.selectedPreProduction.id,
        includes: null,
        payload: {
          attachment_id: this.preProductionFileVersion.id,
        },
      })
    },
  },
}
</script>
