var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.isMobileScreen
      ? 'vms-project__form--fullscreen vd-background-white'
      : 'vd-background-lightest-grey' ]},[_c('div',{class:['vms-project__form--container', _vm.containerClass]},[(_vm.$slots['form-header'])?_c('div',{staticClass:"vms-project__form--header vd-padding-20 vd-padding-top-0 vd-margin-bottom-20 vd-border-lightest-grey vd-background-white"},[_vm._t("form-header")],2):_vm._e(),_c('div',{staticClass:"vms-project__form--body vd-padding-20"},[_vm._t("form-content")],2)]),(!_vm.isReadOnly)?_c('div',{class:[
      'vms-project__form--footer vd-padding-20',
      _vm.footerClass,
      _vm.isMobileScreen ? 'vd-background-lightest-grey' : 'vd-border-white' ]},[_c('div',{staticClass:"row no-gutters vms-project__form--actions"},[_c('div',{staticClass:"vd-margin-right-auto"},[(_vm.isDeleteBtnAvailable)?_c('a',{staticClass:"vd-red",attrs:{"id":_vm.deleteBtnId,"disable":_vm.disableDeleteButton},on:{"click":_vm.deleteResource}},[(_vm.isMobileScreen)?_c('span',{staticClass:"vd-icon-bin"}):_c('span',[_vm._v(" Delete ")])]):_vm._e()]),_c('div',{staticClass:"vd-text-align-right"},[(_vm.canDisplayCancelBtn)?_c('a',{staticClass:"vd-margin-right-20 vd-black",attrs:{"id":_vm.cancelBtnId},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]):_vm._e(),(_vm.showFooterAction)?[_vm._t("additional-buttons"),_c('button',{class:[
              'vms-project__form--btn vd-btn-blue',
              _vm.isMobileScreen
                ? 'vms-project__form--btn__mobile vd-btn-small'
                : 'vd-btn-medium' ],attrs:{"id":_vm.saveBtnId,"disabled":_vm.formSaveBtnDisabled || _vm.disableSaveButton},on:{"click":_vm.saveResource}},[_vm._v(" "+_vm._s(_vm.formSaveBtnLoading ? 'Loading...' : 'Save')+" ")])]:_vm._e()],2),(_vm.useActionDropdown && _vm.isMobileScreen)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeActionsMenu),expression:"closeActionsMenu"}],staticClass:"vd-position-relative"},[_c('button',{staticClass:"vd-btn-small vd-btn-white",attrs:{"id":"action-menu-btn","disabled":_vm.isActionsDisabled},on:{"click":_vm.handleActionsBtnClick}},[_vm._v(" Actions "),_c('i',{staticClass:"vd-icon vd-text-extra-small vd-margin-left-small",class:_vm.showMenu ? 'icon-chevron-up' : 'icon-chevron-down'})]),(_vm.showMenu)?_c('div',{staticClass:"vms-dropdown vms-dropdown--up vms-dropdown--right vd-margin-bottom-small vd-box-shadow vd-background-white vd-border-radius-6"},[_vm._t("additional-buttons"),_c('button',{staticClass:"vms-dropdown__list vd-padding-small vd-border-radius-0 vd-border-transparent vd-full-width vd-text-align-left vd-text-nowrap",attrs:{"role":"button","disabled":_vm.formSaveBtnDisabled || _vm.disableSaveButton},on:{"click":_vm.saveResource}},[_vm._v(" "+_vm._s(_vm.formSaveBtnLoading ? 'Loading...' : 'Save')+" ")])],2):_vm._e()]):_vm._e()])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }