var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('add-edit-form-component',{attrs:{"resource-type":"PreProduction","use-action-dropdown":!_vm.isEditType,"is-actions-disabled":_vm.disableActions},on:{"saveResource":_vm.savePreProduction,"cancelForm":_vm.cancelPreProduction}},[(_vm.isMobileScreen)?_c('div',{staticClass:"vd-display-flex vd-align-middle",attrs:{"slot":"form-header"},slot:"form-header"},[_c('div',{staticClass:"vd-display-flex"},[_c('i',{class:[
            'vms-pre-productions__types--icon vd-margin-right-small vd-icon',
            _vm.formProductionTypeIcon ]}),(!_vm.isEditType)?_c('span',{staticClass:"vd-h3"},[_vm._v(" New "+_vm._s(_vm.selectedPreProductionType.product_element_type.name)+" ")]):_c('span',{staticClass:"vd-h3"},[_vm._v(" Edit "+_vm._s(_vm.selectedPreProduction.type)+" ")])]),_c('div',{staticClass:"vd-margin-left-auto vd-text-align-right"},[_c('span',{staticClass:"vd-icon-close vms-modal__close",on:{"click":_vm.cancelPreProduction}})])]):_vm._e(),_c('div',{attrs:{"slot":"form-content"},slot:"form-content"},[(!_vm.isMobileScreen)?_c('div',{staticClass:"row vd-padding-top-medium vd-padding-bottom-medium"},[_c('div',{staticClass:"vd-padding-left-small vd-padding-right-small"},[_c('i',{class:[
              'vms-pre-productions__types--icon vd-icon',
              _vm.formProductionTypeIcon ]})]),_c('div',{staticClass:"vms-pre-productions__types--name"},[(!_vm.isEditType)?_c('span',[_vm._v(" New "+_vm._s(_vm.selectedPreProductionType.product_element_type.name)+" ")]):_c('span',[_vm._v(" Edit "+_vm._s(_vm.selectedPreProduction.type)+" ")])])]):_vm._e(),(!_vm.isDateTimeChangeAllowed)?_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-12 vd-red vd-text-small"},[(!_vm.hasCalendarEvent)?_c('span',[_vm._v("No calendar events exist")]):_c('span',[_vm._v("Modify duration or dates by using the calendar")])])]):_vm._e(),_c('div',{staticClass:"row vd-margin-top-medium"},[_c('div',{staticClass:"vms-pre-productions__form--column vd-padding-medium"},[_c('div',{staticClass:"vd-padding-bottom-30"},[_c('input-component',{attrs:{"type":"text","name":"preProductionName","label":"Pre-Production Name","placeholder":"Choose a memorable name","required":true,"value":_vm.preProductionNameValue,"container-custom-classes":_vm.setInputValidationClass('preProductionName', _vm.isEditType)},on:{"formInput":function($event){return _vm.handleInputUpdate($event, 'preProductionName')}}})],1),(_vm.isDateTimeChangeAllowed)?_c('div',{staticClass:"vd-field vd-required vd-padding-bottom-30",class:[
              _vm.productionDate ? 'vd-input-valid' : '',
              _vm.setInputValidationClass('productionDate', _vm.isEditType) ]},[_c('label',{staticClass:"vd-position-relative"},[_vm._v("Production Date")]),_c('flat-pickr',{staticClass:"vd-border-light-grey vd-background-transparent vd-input vd-input-valid vd-input-filled vd-required",attrs:{"id":"preProductionProductionDateInput","config":_vm.productionDateConfig,"placeholder":"Select a production date","name":"productionDate"},on:{"input":function($event){return _vm.handleInputUpdate($event, 'productionDate')}},model:{value:(_vm.productionDate),callback:function ($$v) {_vm.productionDate=$$v},expression:"productionDate"}})],1):_vm._e(),(_vm.durationList && _vm.isDateTimeChangeAllowed)?_c('div',{ref:"duration"},[_c('v-select',{class:[
                'vd-field vd-required vd-padding-bottom-30',
                _vm.setInputValidationClass('duration', _vm.isEditType) ],attrs:{"id":"preProductionDurationSelect","placeholder":"Select duration","persistent-placeholder":"","items":_vm.durationList,"hide-selected":true,"required":true,"menu-props":_vm.menuProps,"label":"Duration","no-data-text":"No results to display"},on:{"change":function($event){return _vm.handleInputUpdate($event, 'duration')}},model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}})],1):_vm._e()]),_c('div',{staticClass:"vms-pre-productions__form--column vd-padding-medium vd-margin-bottom-30"},[_c('text-editor',{class:['vd-field', _vm.generalComments ? 'vd-input-valid' : ''],attrs:{"label":"General Comments","content":_vm.generalComments},on:{"update:text-editor-content":_vm.handleGeneralCommentsUpdated}})],1)])]),_c('template',{slot:"additional-buttons"},[(_vm.canHoldJob)?_c('button',{class:[
          'vms-project__form--btn vd-btn-white',
          _vm.isMobileScreen
            ? 'vms-dropdown__list vd-padding-small vd-border-radius-0 vd-border-transparent vd-full-width vd-text-align-left vd-text-nowrap'
            : 'vd-btn-medium vd-margin-right-medium' ],attrs:{"disabled":_vm.isHoldButtonDisabled},on:{"click":_vm.handleClickHoldButton}},[(_vm.onHoldLoading)?_c('span',[_vm._v(" Loading... ")]):_c('span',[_vm._v(" Hold ")])]):_vm._e(),(!_vm.isEditType)?_c('button',{class:[
          'vms-project__form--btn vd-btn-white',
          _vm.isMobileScreen
            ? 'vms-dropdown__list vd-padding-small vd-border-radius-0 vd-border-transparent vd-full-width vd-text-align-left vd-text-nowrap'
            : 'vd-btn-medium vd-margin-right-medium' ],attrs:{"id":"save-without-event-btn","disabled":_vm.isSaveWithoutEventBtnDisabled},on:{"click":_vm.handleClickSaveWithoutEventBtn}},[(_vm.formSaveBtnLoading)?_c('span',[_vm._v(" Loading... ")]):_c('span',[_vm._v("Save without events")])]):_vm._e()])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }