<template>
  <div class="text-center vd-margin-top-medium">
    <button
      :id="`preProductionActionClick-${preProductionItem.id}`"
      :class="[
        'vd-btn-medium btn-block',
        preProductionStatusActionType['color'],
        preProductionStatusActionType['border'],
        preProductionStatusActionType['background'],
      ]"
      :disabled="isPreProductionItemLoading"
      @click="handlePreProductionAction"
    >
      <span v-if="isPreProductionItemLoading">Loading...</span>
      <span v-else>
        {{ preProductionItem.status }}
      </span>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SweetAlert from '@plugins/sweet-alert'
import AppConfig from '@configs/app-config'

export default {
  props: {
    preProductionItem: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      preProductionItemLoading:
        'project/pre-production/preProductionItemLoading',
      selectedPreProduction: 'project/pre-production/selectedPreProduction',
    }),

    isPreProductionItemLoading() {
      return (
        this.preProductionItemLoading &&
        this.selectedPreProduction?.order_job_id ===
          this.preProductionItem.order_job_id
      )
    },

    preProductionStatusActionType() {
      const preProductionStatusTypes = {
        Booked: {
          color: 'vd-light-blue',
          border: 'vd-border-lightest-grey',
          background: 'vd-background-lightest-grey',
        },
        'In Progress': {
          color: 'vd-orange',
          border: 'vd-border-lightest-grey',
          background: 'vd-background-lightest-grey',
        },
        Send: {
          color: 'vd-white',
          border: 'vd-border-orange',
          background: 'vd-background-orange',
        },
        Approve: {
          color: 'vd-white',
          border: 'vd-border-red',
          background: 'vd-background-red',
        },
        Archive: {
          color: 'vd-white',
          border: 'vd-border-green',
          background: 'vd-background-green',
        },
        Archived: {
          color: 'vd-green',
          border: 'vd-border-lightest-grey',
          background: 'vd-background-lightest-grey',
        },
        'On Hold': {
          color: 'vd-yellow',
          border: 'vd-border-lightest-grey',
          background: 'vd-background-cream',
        },
      }

      return preProductionStatusTypes[this.preProductionItem.status]
    },
  },

  methods: {
    ...mapActions({
      updateSelectedPreProductionId:
        'project/pre-production/updateSelectedPreProductionId',
      approvePreProduction: 'project/pre-production/approvePreProduction',
      archivePreProduction: 'project/pre-production/archivePreProduction',
    }),

    handlePreProductionAction() {
      switch (this.preProductionItem.status) {
        case 'Approve':
          this.approvePreProductionDocument()
          break
        case 'Send':
          this.sendPreProductionDocument()
          break
        case 'Archive':
          this.archivePreProductionDocument()
          break
        default:
          // Do nothing
          break
      }
    },

    approvePreProductionDocument() {
      SweetAlert.fire({
        title: 'Warning!',
        html: `Are you sure you want to approve ${this.preProductionItem.name}`,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateSelectedPreProductionId(this.preProductionItem.id)

          this.approvePreProduction({
            project_id: this.project.id,
            order_job_id: this.preProductionItem.order_job_id,
            includes: null,
            name: this.preProductionItem.name,
            approve: true,
          })
        }
      })
    },

    sendPreProductionDocument() {
      const preProductionSendUrl = `${AppConfig.vmsApiUrl}/orders/view-pre-production-delivery-email/${this.preProductionItem.order_job_id}`

      window.open(preProductionSendUrl, '_blank')
    },

    archivePreProductionDocument() {
      SweetAlert.fire({
        title: 'Warning!',
        html: `Are you sure you want to archive ${this.preProductionItem.name}`,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateSelectedPreProductionId(this.preProductionItem.id)

          this.archivePreProduction({
            project_id: this.project.id,
            order_job_id: this.preProductionItem.order_job_id,
            includes: null,
            name: this.preProductionItem.name,
          })
        }
      })
    },
  },
}
</script>
