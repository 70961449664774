<template>
  <div
    :class="[
      isMobileScreen
        ? 'vms-project__form--fullscreen vd-background-white'
        : 'vd-background-lightest-grey',
    ]"
  >
    <div :class="['vms-project__form--container', containerClass]">
      <div
        v-if="$slots['form-header']"
        class="vms-project__form--header vd-padding-20 vd-padding-top-0 vd-margin-bottom-20 vd-border-lightest-grey vd-background-white"
      >
        <slot name="form-header"></slot>
      </div>

      <div class="vms-project__form--body vd-padding-20">
        <slot name="form-content"></slot>
      </div>
    </div>

    <div
      v-if="!isReadOnly"
      :class="[
        'vms-project__form--footer vd-padding-20',
        footerClass,
        isMobileScreen ? 'vd-background-lightest-grey' : 'vd-border-white',
      ]"
    >
      <div class="row no-gutters vms-project__form--actions">
        <div class="vd-margin-right-auto">
          <a
            v-if="isDeleteBtnAvailable"
            :id="deleteBtnId"
            :disable="disableDeleteButton"
            class="vd-red"
            @click="deleteResource"
          >
            <span v-if="isMobileScreen" class="vd-icon-bin"></span>
            <span v-else> Delete </span>
          </a>
        </div>

        <div class="vd-text-align-right">
          <a
            v-if="canDisplayCancelBtn"
            :id="cancelBtnId"
            class="vd-margin-right-20 vd-black"
            @click="cancel"
          >
            Cancel
          </a>

          <template v-if="showFooterAction">
            <slot name="additional-buttons"></slot>

            <button
              :id="saveBtnId"
              :class="[
                'vms-project__form--btn vd-btn-blue',
                isMobileScreen
                  ? 'vms-project__form--btn__mobile vd-btn-small'
                  : 'vd-btn-medium',
              ]"
              :disabled="formSaveBtnDisabled || disableSaveButton"
              @click="saveResource"
            >
              {{ formSaveBtnLoading ? 'Loading...' : 'Save' }}
            </button>
          </template>
        </div>

        <div
          v-if="useActionDropdown && isMobileScreen"
          v-click-outside="closeActionsMenu"
          class="vd-position-relative"
        >
          <button
            id="action-menu-btn"
            class="vd-btn-small vd-btn-white"
            :disabled="isActionsDisabled"
            @click="handleActionsBtnClick"
          >
            Actions
            <i
              class="vd-icon vd-text-extra-small vd-margin-left-small"
              :class="showMenu ? 'icon-chevron-up' : 'icon-chevron-down'"
            ></i>
          </button>

          <div
            v-if="showMenu"
            class="vms-dropdown vms-dropdown--up vms-dropdown--right vd-margin-bottom-small vd-box-shadow vd-background-white vd-border-radius-6"
          >
            <slot name="additional-buttons"></slot>
            <button
              role="button"
              class="vms-dropdown__list vd-padding-small vd-border-radius-0 vd-border-transparent vd-full-width vd-text-align-left vd-text-nowrap"
              :disabled="formSaveBtnDisabled || disableSaveButton"
              @click="saveResource"
            >
              {{ formSaveBtnLoading ? 'Loading...' : 'Save' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside,
  },
  props: {
    containerClass: {
      type: String,
      required: false,
      default: '',
    },

    footerClass: {
      type: String,
      required: false,
      default: '',
    },

    resourceType: {
      type: String,
      required: true,
    },

    isFullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },

    isReadOnly: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableSaveButton: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableCancelButton: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableDeleteButton: {
      type: Boolean,
      required: false,
      default: false,
    },

    useActionDropdown: {
      type: Boolean,
      required: false,
      default: false,
    },

    isActionsDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      cancelBtnDisabled: false,
      showMenu: false,
    }
  },

  inject: ['formActionType'],

  computed: {
    ...mapGetters({
      formSaveBtnDisabled: 'project/formSaveBtnDisabled',
      formSaveBtnLoading: 'project/formSaveBtnLoading',
      isMobileScreen: 'common/isMobileScreen',
    }),

    isCreateType() {
      return this.formActionType === 'create'
    },

    saveBtnId() {
      return this.isCreateType
        ? `submitAdd${this.resourceType}FormButton`
        : `submitEdit${this.resourceType}FormButton`
    },

    cancelBtnId() {
      return this.isCreateType
        ? `cancelAdd${this.resourceType}FormLink`
        : `cancelEdit${this.resourceType}FormLink`
    },

    deleteBtnId() {
      return `deleteEdit${this.resourceType}FormLink`
    },

    canDisplayCancelBtn() {
      return (
        !this.cancelBtnDisabled &&
        !this.formSaveBtnLoading &&
        !this.disableCancelButton
      )
    },

    /**
     * Delete feature is only available for Filming Details edit form.
     * All other resources have their own Delete option under the 3 dot menu.
     * TODO: Remove this once we have implemented a 3 dot menu for Filming Details.
     */
    isDeleteBtnAvailable() {
      return (
        this.$route.name === 'project.details.filming-details' &&
        !this.isCreateType &&
        this.canDisplayCancelBtn &&
        !this.disableDeleteButton
      )
    },

    showFooterAction() {
      return this.useActionDropdown ? !this.isMobileScreen : true
    },
  },

  methods: {
    ...mapActions({
      updateFormSaveBtnLoading: 'project/updateFormSaveBtnLoading',
    }),

    cancel() {
      this.$emit('cancelForm')
    },

    saveResource() {
      this.$emit('saveResource')
      this.updateFormSaveBtnLoading(true)
      this.cancelBtnDisabled = true
      this.closeActionsMenu()
    },

    deleteResource() {
      this.$emit('deleteResource')
    },

    handleActionsBtnClick() {
      this.showMenu = !this.showMenu
    },

    closeActionsMenu() {
      this.showMenu = false
    },
  },
}
</script>

<style lang="scss">
@import '@styles/components/forms/input';
@import '@styles/components/forms/select';
</style>
