<template>
  <div class="vd-margin-bottom-30">
    <div v-if="isUserSelectDisabled" class="vd-field">
      <label for="" class="vd-position-relative">Team member</label>
      <template v-if="isOrderJobChangeDone">
        <div
          v-if="hasAssignedUser"
          class="vd-border-grey vd-border-width-0 vd-border-bottom-width-1"
        >
          <div
            class="vd-display-flex vd-align-center vd-margin-bottom-extra-small"
          >
            <user-profile
              :user="assignedUser"
              :custom-avatar-font-size="35"
              class="vd-margin-right-10"
            ></user-profile>

            {{ assignedUser.full_name }}
          </div>
        </div>

        <div
          v-else
          class="vd-border-grey vd-border-width-0 vd-border-bottom-width-1"
        >
          Unassigned
        </div>
      </template>

      <input
        v-else
        :placeholder="isUsersListLoading ? 'Loading...' : 'Unassigned'"
        disabled
      />
    </div>

    <v-select
      v-else
      id="userSelect"
      :value="selectedUserId"
      :class="['vd-field ', selectedUserId ? 'vd-input-valid' : '']"
      :items="usersList"
      :disabled="isDisabled"
      :menu-props="menuProps"
      placeholder="Who will work on the changes?"
      persistent-placeholder
      label="Team member"
      item-text="full_name"
      item-value="id"
      no-data-text="No results to display"
      clearable
      @change="$emit('input:selectedUserId', $event)"
    >
      <template v-slot:selection="{ item }">
        <div
          class="vd-display-flex vd-align-center vd-margin-bottom-extra-small"
        >
          <user-profile
            :user="item"
            :custom-avatar-font-size="35"
            class="vd-margin-right-10"
          ></user-profile>

          <span>{{ item.full_name }}</span>
        </div>
      </template>
      <template v-slot:item="{ item }">
        <user-profile
          :user="item"
          :custom-avatar-font-size="35"
          class="vd-margin-right-small"
        ></user-profile>

        <span>{{ item.full_name }}</span>
      </template>
    </v-select>
  </div>
</template>

<script>
import UserProfile from '@components/user-profile/user-profile'
import Vuetify from 'vuetify'
import { isEmpty } from 'lodash'

export default {
  components: {
    UserProfile,
  },
  model: {
    prop: 'selectedUserId',
    event: 'input:selectedUserId',
  },

  props: {
    assignedUser: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    isOrderJobChangeDone: {
      type: Boolean,
      required: false,
      default: false,
    },

    isUsersListLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    selectedUserId: {
      type: Number,
      required: false,
      default: null,
    },

    usersList: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  vuetify: new Vuetify(),

  data() {
    return {
      menuProps: { closeOnClick: true },
    }
  },

  computed: {
    isUserSelectDisabled() {
      return (
        this.isOrderJobChangeDone ||
        this.isUsersListLoading ||
        isEmpty(this.usersList)
      )
    },

    hasAssignedUser() {
      return !isEmpty(this.assignedUser)
    },
  },
}
</script>
