var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{class:[
      'vms-job-changes-form',
      _vm.isMobileScreen
        ? 'vms-project__form--fullscreen vd-background-white'
        : 'vd-background-lightest-grey' ]},[_c('div',{staticClass:"vms-project__form--container"},[(_vm.isMobileScreen)?_c('div',{staticClass:"vms-project__form--header vd-display-flex vd-align-center vd-padding-20 vd-margin-bottom-20 vd-border-lightest-grey vd-background-white"},[(_vm.icon)?_c('span',{class:['vd-icon vd-margin-right-small', _vm.icon]}):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" "),(!_vm.isLoading)?_c('span',{staticClass:"vd-icon icon-xmark vd-cursor-pointer vd-darker-grey vd-hover-black vd-margin-left-auto vms-modal__close",on:{"click":_vm.handleCancel}}):_vm._e()]):_vm._e(),_c('div',{class:[
          'vms-project__form--body vd-border-white',
          _vm.isMobileScreen ? 'vd-padding-small' : 'vd-padding-medium' ]},[(!_vm.isMobileScreen)?_c('div',{staticClass:"vd-padding-small vd-display-flex vd-align-center vd-h4"},[(_vm.icon)?_c('span',{class:['vd-icon vd-margin-right-small', _vm.icon]}):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.isOrderJobChangeDone)?_c('span',{staticClass:"vd-icon icon-xmark vd-cursor-pointer vd-darker-grey vd-hover-black vd-margin-left-auto",on:{"click":_vm.handleCancel}}):_vm._e()]):_vm._e(),(_vm.isLoading)?_c('default-loader',{staticClass:"vd-margin-block-50"}):_c('div',{class:[
            !_vm.isMobileScreen
              ? 'vd-display-flex vd-flex-wrap vd-margin-top-medium'
              : '' ]},[_c('div',{staticClass:"vms-job-changes-form__column vd-padding-medium"},[_c('div',{staticClass:"vd-margin-bottom-30"},[(_vm.isOrderJobChangeDone)?_c('div',{staticClass:"vd-field"},[_c('label',[_vm._v("Date")]),(_vm.isOrderJobChangeDone)?_c('div',{staticClass:"vd-border-grey vd-border-width-0 vd-border-bottom-width-1"},[_vm._v(" "+_vm._s(_vm.productionDate)+" ")]):_vm._e()]):_c('div',{class:[
                  'vd-field vd-required',
                  _vm.setInputValidationClass('productionDate') ]},[_c('label',{staticClass:"vd-position-relative"},[_vm._v("Date")]),_c('flat-pickr',{attrs:{"id":"changeDate","config":_vm.productionDateConfig,"placeholder":"When will changes happen?","name":"productionDate","disabled":_vm.isLoading},on:{"input":function($event){return _vm.handleInputUpdate($event, 'productionDate')}},model:{value:(_vm.productionDate),callback:function ($$v) {_vm.productionDate=$$v},expression:"productionDate"}})],1)]),_c('div',{staticClass:"vd-margin-bottom-30"},[(_vm.isOrderJobChangeDone)?_c('div',{staticClass:"vd-field"},[_c('label',[_vm._v("Start Time")]),(_vm.isOrderJobChangeDone)?_c('div',{staticClass:"vd-border-grey vd-border-width-0 vd-border-bottom-width-1"},[_vm._v(" "+_vm._s(_vm.productionTime)+" ")]):_vm._e()]):_c('form-time-picker',{class:[
                  'vd-field vd-required',
                  _vm.setInputValidationClass('productionTime') ],attrs:{"id":"jobChangesProductionTime","selected-date":_vm.productionDate,"disabled":_vm.isLoading,"label":"Start Time","placeholder":"Select time"},on:{"change":function($event){return _vm.handleInputUpdate($event, 'productionTime')}},model:{value:(_vm.productionTime),callback:function ($$v) {_vm.productionTime=$$v},expression:"productionTime"}})],1),_c('div',{staticClass:"vd-padding-bottom-30 vd-position-relative"},[(_vm.isOrderJobChangeDone)?_c('div',{staticClass:"vd-field"},[_c('label',{staticClass:"vd-position-relative",attrs:{"for":""}},[_vm._v("Time required")]),_c('div',{staticClass:"vd-border-grey vd-border-width-0 vd-border-bottom-width-1"},[_vm._v(" "+_vm._s(_vm.duration)+" ")])]):_c('v-select',{class:[
                  'vd-field vd-required ',
                  _vm.setInputValidationClass('duration') ],attrs:{"id":"durationSelect","placeholder":"How long is needed?","persistent-placeholder":"","items":_vm.durationList,"disabled":_vm.isLoading,"hide-selected":true,"required":true,"menu-props":_vm.menuProps,"label":"Time required","no-data-text":"No results to display"},on:{"change":function($event){return _vm.handleInputUpdate($event, 'duration')}},model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}})],1),_c('job-changes-team-members',{attrs:{"users-list":_vm.usersList,"assigned-user":_vm.assignedUser,"is-disabled":_vm.isLoading,"is-users-list-loading":_vm.isUsersListLoading,"is-order-job-change-done":_vm.isOrderJobChangeDone},model:{value:(_vm.editor),callback:function ($$v) {_vm.editor=$$v},expression:"editor"}})],1),_c('job-changes-attachments',{staticClass:"vms-job-changes-form__column vd-padding-medium",attrs:{"attachment-api-url":_vm.attachmentApiUrl,"is-attachments-loading":_vm.isAttachmentsLoading,"is-order-job-change-done":_vm.isOrderJobChangeDone,"has-job-change":_vm.hasJobChange,"attachments":_vm.attachments},on:{"click:add-file":_vm.handleAddFile,"click:delete-attachment":_vm.handleDeleteAttachment,"update:attachments":_vm.handleProcessFile}}),_c('div',{staticClass:"vms-job-changes-form__column vd-padding-medium"},[(_vm.isOrderJobChangeDone)?_c('div',{staticClass:"vd-field"},[_c('label',[_vm._v("Description")]),(_vm.description)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.description)}}):_c('div',{staticClass:"vd-full-width vd-padding-medium vd-padding-bottom-extra-large vd-background-lighter-grey vd-dark-grey vd-border-radius-6"},[_vm._v(" No description ")])]):_c('text-editor',{staticClass:"vd-field",attrs:{"label":"Description","content":_vm.description},on:{"update:text-editor-content":_vm.handleEditorUpdate}})],1)],1)],1)]),(!_vm.isOrderJobChangeDone)?_c('div',{class:[
        'vms-project__form--footer vd-padding-medium',
        _vm.isMobileScreen ? 'vd-background-lightest-grey' : 'vd-border-white' ]},[_c('div',{staticClass:"vms-project__form--actions vd-text-align-right"},[(!_vm.isLoading)?_c('button',{staticClass:"vd-margin-right-40 vd-black vd-a",on:{"click":_vm.handleCancel}},[_vm._v(" Cancel ")]):_vm._e(),_c('button',{staticClass:"vms-project__form--btn vd-btn-blue vd-btn-medium",attrs:{"disabled":_vm.isSaveBtnDisabled},on:{"click":_vm.handleSave}},[_vm._v(" "+_vm._s(_vm.isLoading ? 'Loading...' : 'Save')+" ")])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }