<template>
  <default-modal
    class="show"
    modal-dialog-class="modal-lg"
    content-class="vd-background-white"
  >
    <div slot="modal-header" class="modal-header">
      <div class="vd-h4 vd-light-blue">
        File versions for
        {{ selectedPreProduction.name | truncateString(34) }}
      </div>

      <div class="vms-modal__header--close close vd-black" @click="closeModal">
        <span id="closeFileVersionModalSpan">x</span>
      </div>
    </div>
    <div slot="modal-body" class="modal-body">
      <div v-if="preProductionFileVersionsLoading">
        <default-loader></default-loader>
      </div>

      <div
        v-if="
          !preProductionFileVersionsLoading &&
          preProductionFileVersions.length === 0
        "
      >
        <div class="vms-pre-productions__empty vd-text-align-center">
          <img src="/images/empty-states/empty-pre-prod.svg" />

          <p>No previous document versions to display.</p>
        </div>
      </div>

      <div
        v-if="
          !preProductionFileVersionsLoading &&
          preProductionFileVersions.length > 0
        "
      >
        <table class="vms-project-files__table table table-sm">
          <thead>
            <tr>
              <th scope="col" class="vd-h4 vd-darker-grey vd-border-light-grey">
                Last modified at
              </th>
              <th scope="col" class="vd-h4 vd-darker-grey vd-border-light-grey">
                Last modified by
              </th>
              <th
                scope="col"
                class="vd-h4 vd-darker-grey vd-border-light-grey"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(fileVersion, index) in preProductionFileVersions"
              :key="index"
            >
              <td class="vd-border-light-grey">
                {{
                  fileVersion.created_at | unixToFormat('DD/MM/YY @ hh:mm a')
                }}
              </td>
              <td class="vd-border-light-grey">
                <div
                  v-if="fileVersion.created_by.photo_url === null"
                  class="vms-project-files__icon--user vd-display-inline-block vd-margin-right-small vd-icon-profile"
                ></div>
                <div
                  v-else
                  class="vms-project-files__icon--profile vd-display-inline-block vd-margin-right-small"
                >
                  <img
                    class="vd-border-radius-50"
                    :src="fileVersion.created_by.photo_url"
                  />
                </div>
                <div class="vd-display-inline-block">
                  {{ fileVersion.created_by.full_name }}
                </div>
              </td>
              <td class="vd-border-light-grey vd-text-align-right">
                <div
                  class="vms-project-files__three-dot vd-display-inline-block vd-position-relative"
                >
                  <pre-production-file-version-actions-dropdown
                    :pre-production-file-version="fileVersion"
                  ></pre-production-file-version-actions-dropdown>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </default-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FiltersMixin from '@mixins/filters-mixin'
import DefaultModal from '@components/modal/default-modal'
import DefaultLoader from '@components/loaders/default-loader'
import PreProductionFileVersionActionsDropdown from '@views/project/pre-production/components/pre-production-file-version-actions-dropdown'

export default {
  components: {
    DefaultModal,
    DefaultLoader,
    PreProductionFileVersionActionsDropdown,
  },

  mixins: [FiltersMixin],

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      selectedPreProduction: 'project/pre-production/selectedPreProduction',
      preProductionFileVersions:
        'project/pre-production/preProductionFileVersions',
      preProductionFileVersionsLoading:
        'project/pre-production/preProductionFileVersionsLoading',
    }),
  },

  beforeMount() {
    this.getPreProductionFileVersions({
      project_id: this.project.id,
      order_job_id: this.selectedPreProduction.id,
      includes: ['created_by'],
    })
  },

  methods: {
    ...mapActions({
      toggleFileVersionsModalShow:
        'project/pre-production/toggleFileVersionsModalShow',
      getPreProductionFileVersions:
        'project/pre-production/getPreProductionFileVersions',
      updateSelectedPreProductionId:
        'project/pre-production/updateSelectedPreProductionId',
      updatePreProductionFileVersions:
        'project/pre-production/updatePreProductionFileVersions',
    }),

    closeModal() {
      this.toggleFileVersionsModalShow()
      this.updateSelectedPreProductionId(null)
      this.updatePreProductionFileVersions([])
    },
  },
}
</script>

<style lang="scss">
@import '@styles/views/project/uploaded-files';
</style>
