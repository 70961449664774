<template>
  <div
    :class="[
      'vms-job-changes-list',
      isMobileScreen
        ? 'vms-project__form--fullscreen vd-background-white'
        : 'vd-background-lightest-grey',
    ]"
  >
    <div class="vms-project__form--container">
      <div
        v-if="isMobileScreen"
        class="vms-project__form--header vd-display-flex vd-align-center vd-padding-20 vd-margin-bottom-20 vd-border-lightest-grey vd-background-white"
      >
        <span v-if="icon" :class="['vd-icon vd-margin-right-small', icon]" />

        {{ title }}

        <span
          v-if="!isJobChangesLoading"
          class="vd-icon icon-xmark vd-cursor-pointer vd-darker-grey vd-hover-black vd-margin-left-auto vms-modal__close"
          @click="handleClose"
        ></span>
      </div>

      <div class="vms-project__form--body vd-padding-small vd-border-white">
        <div
          v-if="!isMobileScreen"
          class="vd-padding-small vd-display-flex vd-align-center vd-h4"
        >
          <span v-if="icon" :class="['vd-icon vd-margin-right-small', icon]" />

          {{ title }}

          <button
            class="vd-btn-blue vd-btn-medium vd-margin-left-medium"
            :disabled="isJobChangesLoading"
            @click="handleAddJobChange"
          >
            Open new changes
          </button>

          <span
            class="vd-icon icon-xmark vd-cursor-pointer vd-darker-grey vd-hover-black vd-margin-left-auto"
            @click="handleClose"
          ></span>
        </div>

        <default-loader
          v-if="isJobChangesLoading"
          class="vd-margin-block-50"
        ></default-loader>

        <template v-else-if="hasJobChanges">
          <job-changes-item
            v-for="(jobChange, index) in jobChangesList"
            :key="index"
            :display-name="getJobChangeName(index)"
            :job-change="jobChange"
            v-on="$listeners"
          ></job-changes-item>
        </template>

        <div v-else class="vd-padding-medium vd-margin-medium">
          <div class="vd-text-align-center">
            <img
              src="/images/empty-states/empty-pre-prod.svg"
              width="300"
              height="300"
            />
            <div class="vd-text-align-center">
              No changes scheduled. Click
              <button
                class="vd-inline-block vd-border-width-0 vd-border-bottom-width-1 vd-light-blue vd-border-light-blue"
                @click="handleAddJobChange"
              >
                Open new changes
              </button>
              to begin.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isMobileScreen"
      class="vms-project__form--footer vd-padding-medium vd-background-lightest-grey"
    >
      <div class="vms-project__form--actions vd-text-align-right">
        <button
          class="vms-project__form--btn vd-btn-blue vd-btn-medium"
          :disabled="isJobChangesLoading"
          @click="handleAddJobChange"
        >
          Open new changes
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import JobChangesItem from '@views/project/components/forms/job-changes-form/components/job-changes-item'
import DefaultLoader from '@components/loaders/default-loader'
import { isEmpty, size } from 'lodash'

export default {
  components: {
    JobChangesItem,
    DefaultLoader,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: 'Order Job Changes',
    },

    icon: {
      type: String,
      required: false,
      default: '',
    },

    jobChangesList: {
      type: Array,
      required: false,
      default: () => [],
    },

    isJobChangesLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
    }),

    hasJobChanges() {
      return !isEmpty(this.jobChangesList)
    },
  },

  methods: {
    handleAddJobChange() {
      this.$emit('click:add-job-change')
    },

    handleClose() {
      this.$emit('click:close')
    },

    getJobChangeName(index) {
      return `Change ${size(this.jobChangesList) - index}`
    },
  },
}
</script>

<style lang="scss">
@import '@styles/v2/views/project/components/forms/job-changes-form/job-changes-list.scss';
</style>
