<template>
  <default-modal
    class="show"
    modal-dialog-class="modal-lg"
    content-class="vd-background-white"
  >
    <div slot="modal-header" class="modal-header">
      <div class="vd-h4 vd-light-blue">
        Pre-Production File Upload for
        {{ selectedPreProduction.name | truncateString(34) }}
      </div>

      <div
        v-if="!fileUploadCompleted"
        class="vms-modal__header--close close vd-black"
        @click="closeModal"
      >
        <span id="closeFileUploadModalSpan">x</span>
      </div>
    </div>
    <div slot="modal-body" class="modal-body">
      <div
        v-if="largeFileWarning"
        class="vd-margin-bottom-10 vd-padding-left-medium vd-padding-top-small vd-padding-bottom-small vd-display-block vd-border-radius-6 vd-background-light-yellow vd-red"
      >
        Please don't close this window, large file may take a little longer to
        process.
      </div>

      <div
        v-if="!fileUploadCompleted"
        class="vd-border-lighter-grey vd-border-radius-6 vd-padding-20 vd-grey vd-text-align-center vd-margin-bottom-20"
      >
        <div
          class="vms-uploader__wrapper vd-border-lighter-grey vd-border-radius-6 vd-padding-top-20 vd-padding-bottom-20"
        >
          <file-upload-component
            :api-url="apiUrl"
            :label-idle="defaultLabel"
            :allow-multiple="false"
            @handle:process-file="handleProcessFile"
            @handle:add-file="handleAddFile"
            @handle:remove-file="handleRemoveFile"
          ></file-upload-component>
        </div>
      </div>

      <div v-if="fileUploadCompleted">
        <default-loader></default-loader>
      </div>
    </div>
  </default-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FiltersMixin from '@mixins/filters-mixin'
import DefaultModal from '@components/modal/default-modal'
import FileUploadComponent from '@components/file-upload/file-upload'
import AppConfig from '@configs/app-config'
import { isNull } from 'lodash'
import DefaultLoader from '@components/loaders/default-loader'

export default {
  components: {
    DefaultModal,
    FileUploadComponent,
    DefaultLoader,
  },

  mixins: [FiltersMixin],

  data() {
    return {
      fileUploadCompleted: false,
      largeFileWarning: false,
      maxFileSizeWarning: 100000000, // 100MB
      fileName: null,
    }
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      selectedPreProduction: 'project/pre-production/selectedPreProduction',
    }),

    apiUrl() {
      return `${AppConfig.vmsApiUrl}/api/v2/internal/projects/${this.project.id}/jobs/pre-production/${this.selectedPreProduction.id}/file-upload`
    },

    defaultLabel() {
      return `<div class='vd-dark-grey vms-uploader__text'>Drop your file here or click to <u class='vd-light-blue'>browse</u></div><div class='vd-grey'>Supports pdf, doc, xls and ppt documents. Maximum file size 2GB.</div>`
    },
  },

  methods: {
    ...mapActions({
      toggleFileUploadModalShow:
        'project/pre-production/toggleFileUploadModalShow',
      uploadPreProductionDocument:
        'project/pre-production/uploadPreProductionDocument',
      updateSelectedPreProductionId:
        'project/pre-production/updateSelectedPreProductionId',
    }),

    handleProcessFile() {
      this.fileUploadCompleted = true

      // Send api call to initiate file upload to google drive.
      this.uploadPreProductionDocument({
        project_id: this.project.id,
        order_job_id: this.selectedPreProduction.id,
        includes: null,
        payload: {
          file_name: this.fileName,
        },
      })
    },

    handleAddFile(error, file) {
      this.fileName = file.file.name

      if (isNull(error)) {
        // If file is bigger than 100MB we will show the warning.
        if (file.file.size > this.maxFileSizeWarning) {
          this.largeFileWarning = true
        }
      }
    },

    handleRemoveFile() {
      this.largeFileWarning = false
    },

    closeModal() {
      this.toggleFileUploadModalShow()
      this.updateSelectedPreProductionId(null)
    },
  },
}
</script>
