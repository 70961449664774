<template>
  <div v-click-outside="closeMenu" class="vd-position-relative">
    <i
      class="vd-icon icon-ellipsis-vertical vd-icon-circle vd-margin-top-extra-small vd-background-lightest-grey vd-hover-background-lighter-grey vd-dark-grey"
      @click="handleMenuClick"
    ></i>

    <div
      v-if="showMenu"
      class="vms-dropdown vms-job-changes-list__menu vd-box-shadow vd-background-white vd-border-radius-6"
    >
      <div
        role="button"
        class="vms-dropdown__list vd-padding-small"
        @click="handleView"
      >
        {{ canEdit ? 'View/Edit' : 'View' }}
      </div>
      <div
        v-if="canMarkAsComplete"
        role="button"
        class="vms-dropdown__list vd-padding-small"
        @click="handleMarkAsComplete"
      >
        Mark as complete
      </div>
      <div
        role="button"
        class="vms-dropdown__list vd-padding-small"
        @click="handleDelete"
      >
        Delete
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside,
  },
  props: {
    canEdit: {
      type: Boolean,
      required: false,
      default: false,
    },

    canMarkAsComplete: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      showMenu: false,
    }
  },

  methods: {
    handleMenuClick() {
      this.showMenu = true
    },

    closeMenu() {
      this.showMenu = false
    },

    handleView() {
      this.$emit('click:view')
      this.closeMenu()
    },

    handleMarkAsComplete() {
      this.$emit('click:mark-as-complete')
      this.closeMenu()
    },

    handleDelete() {
      this.$emit('click:delete')
      this.closeMenu()
    },
  },
}
</script>
