<template>
  <v-app>
    <add-edit-form-component
      resource-type="PreProduction"
      :use-action-dropdown="!isEditType"
      :is-actions-disabled="disableActions"
      @saveResource="savePreProduction"
      @cancelForm="cancelPreProduction"
    >
      <div
        v-if="isMobileScreen"
        slot="form-header"
        class="vd-display-flex vd-align-middle"
      >
        <div class="vd-display-flex">
          <i
            :class="[
              'vms-pre-productions__types--icon vd-margin-right-small vd-icon',
              formProductionTypeIcon,
            ]"
          />
          <span v-if="!isEditType" class="vd-h3">
            New {{ selectedPreProductionType.product_element_type.name }}
          </span>
          <span v-else class="vd-h3">
            Edit {{ selectedPreProduction.type }}
          </span>
        </div>

        <div class="vd-margin-left-auto vd-text-align-right">
          <span
            class="vd-icon-close vms-modal__close"
            @click="cancelPreProduction"
          ></span>
        </div>
      </div>

      <div slot="form-content">
        <div
          v-if="!isMobileScreen"
          class="row vd-padding-top-medium vd-padding-bottom-medium"
        >
          <div class="vd-padding-left-small vd-padding-right-small">
            <i
              :class="[
                'vms-pre-productions__types--icon vd-icon',
                formProductionTypeIcon,
              ]"
            />
          </div>
          <div class="vms-pre-productions__types--name">
            <span v-if="!isEditType">
              New {{ selectedPreProductionType.product_element_type.name }}
            </span>
            <span v-else> Edit {{ selectedPreProduction.type }} </span>
          </div>
        </div>

        <div v-if="!isDateTimeChangeAllowed" class="row no-gutters">
          <div class="col-12 vd-red vd-text-small">
            <span v-if="!hasCalendarEvent">No calendar events exist</span>
            <span v-else>Modify duration or dates by using the calendar</span>
          </div>
        </div>

        <div class="row vd-margin-top-medium">
          <div class="vms-pre-productions__form--column vd-padding-medium">
            <div class="vd-padding-bottom-30">
              <input-component
                type="text"
                name="preProductionName"
                label="Pre-Production Name"
                placeholder="Choose a memorable name"
                :required="true"
                :value="preProductionNameValue"
                :container-custom-classes="
                  setInputValidationClass('preProductionName', isEditType)
                "
                @formInput="handleInputUpdate($event, 'preProductionName')"
              ></input-component>
            </div>

            <div
              v-if="isDateTimeChangeAllowed"
              class="vd-field vd-required vd-padding-bottom-30"
              :class="[
                productionDate ? 'vd-input-valid' : '',
                setInputValidationClass('productionDate', isEditType),
              ]"
            >
              <label class="vd-position-relative">Production Date</label>
              <flat-pickr
                id="preProductionProductionDateInput"
                v-model="productionDate"
                :config="productionDateConfig"
                class="vd-border-light-grey vd-background-transparent vd-input vd-input-valid vd-input-filled vd-required"
                placeholder="Select a production date"
                name="productionDate"
                @input="handleInputUpdate($event, 'productionDate')"
              >
              </flat-pickr>
            </div>

            <div v-if="durationList && isDateTimeChangeAllowed" ref="duration">
              <v-select
                id="preProductionDurationSelect"
                v-model="duration"
                placeholder="Select duration"
                persistent-placeholder
                :class="[
                  'vd-field vd-required vd-padding-bottom-30',
                  setInputValidationClass('duration', isEditType),
                ]"
                :items="durationList"
                :hide-selected="true"
                :required="true"
                :menu-props="menuProps"
                label="Duration"
                no-data-text="No results to display"
                @change="handleInputUpdate($event, 'duration')"
              >
              </v-select>
            </div>
          </div>

          <div
            class="vms-pre-productions__form--column vd-padding-medium vd-margin-bottom-30"
          >
            <text-editor
              label="General Comments"
              :class="['vd-field', generalComments ? 'vd-input-valid' : '']"
              :content="generalComments"
              @update:text-editor-content="handleGeneralCommentsUpdated"
            ></text-editor>
          </div>
        </div>
      </div>

      <template slot="additional-buttons">
        <button
          v-if="canHoldJob"
          :class="[
            'vms-project__form--btn vd-btn-white',
            isMobileScreen
              ? 'vms-dropdown__list vd-padding-small vd-border-radius-0 vd-border-transparent vd-full-width vd-text-align-left vd-text-nowrap'
              : 'vd-btn-medium vd-margin-right-medium',
          ]"
          :disabled="isHoldButtonDisabled"
          @click="handleClickHoldButton"
        >
          <span v-if="onHoldLoading"> Loading... </span>
          <span v-else> Hold </span>
        </button>

        <button
          v-if="!isEditType"
          id="save-without-event-btn"
          :class="[
            'vms-project__form--btn vd-btn-white',
            isMobileScreen
              ? 'vms-dropdown__list vd-padding-small vd-border-radius-0 vd-border-transparent vd-full-width vd-text-align-left vd-text-nowrap'
              : 'vd-btn-medium vd-margin-right-medium',
          ]"
          :disabled="isSaveWithoutEventBtnDisabled"
          @click="handleClickSaveWithoutEventBtn"
        >
          <span v-if="formSaveBtnLoading"> Loading... </span>
          <span v-else>Save without events</span>
        </button>
      </template>
    </add-edit-form-component>
  </v-app>
</template>

<style>
@import '~flatpickr/dist/flatpickr.css';
</style>

<script>
import { mapGetters, mapActions } from 'vuex'
import AddEditFormComponent from '@views/project/components/forms/form-main'
import SweetAlert from '@plugins/sweet-alert'
import InputComponent from '@components/form/form-input'
import FormMixin from '@mixins/forms-mixin'
import { required, minLength } from 'vuelidate/lib/validators'
import momentTz from 'moment-timezone'
import FlatPickr from 'vue-flatpickr-component'
import DurationsConstants from '@configs/all-durations'
import { isEmpty, isEqual, find, isNil, map, entries } from 'lodash'
import TextEditor from '@components/text-editor/text-editor'
import VmsConstants from '@configs/vms-constants'
import Vuetify from 'vuetify'

export default {
  components: {
    InputComponent,
    AddEditFormComponent,
    FlatPickr,
    TextEditor,
  },

  mixins: [FormMixin],

  vuetify: new Vuetify(),

  data() {
    return {
      preProductionName: '',
      productionDate: null,
      generalComments: '',
      duration: null,
      formProductionTypeIcon: '',
      defaultPayload: {},
      onHoldLoading: false,
      onSaveWithoutEventLoading: false,
      menuProps: { closeOnClick: true },
    }
  },

  validations: {
    preProductionName: {
      required,
      minLength: minLength(1),
    },

    productionDate: {
      required,
    },

    duration: {
      required,
    },
  },

  inject: ['formActionType', 'selectedProductionTypeIcon'],

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      selectedPreProductionType:
        'project/pre-production/selectedPreProductionType',
      selectedPreProduction: 'project/pre-production/selectedPreProduction',
      isMobileScreen: 'common/isMobileScreen',
      preProductionLinkingJob: 'project/pre-production/preProductionLinkingJob',
      preProductionNewFormOpen:
        'project/pre-production/preProductionNewFormOpen',
      filmingDetails: 'project/shoot/filmingDetails',
      deliveries: 'project/delivery/deliveries',
      formSaveBtnDisabled: 'project/formSaveBtnDisabled',
      formSaveBtnLoading: 'project/formSaveBtnLoading',
    }),

    isEditType() {
      return this.formActionType === 'edit'
    },

    isFormInvalid() {
      return this.$v.$invalid
    },

    productionDateConfig() {
      return {
        enableTime: false,
        allowInput: true,
        altFormat: 'd M Y',
        dateFormat: 'd M Y',
        altInput: true,
        disableMobile: 'true',
        minDate:
          !this.isEditType || !this.selectedPreProduction?.production_date
            ? 'today'
            : null,
      }
    },

    preProductionNameValue() {
      return this.isEditType
        ? !isEmpty(this.selectedPreProduction)
          ? this.selectedPreProduction.name
          : ''
        : ''
    },

    changedPayload() {
      return {
        preProductionName: this.preProductionName,
        productionDate: this.productionDate,
        duration: parseInt(this.duration),
        generalComments: this.generalComments,
      }
    },

    isPreProductionJobOnHold() {
      return (
        this.selectedPreProduction?.status_id ===
        VmsConstants.orderJobStatuses.ORDER_JOB_ON_HOLD
      )
    },

    isDateTimeChangeAllowed() {
      return (
        (!this.isEditType ||
          this.project.project_status_id ===
            VmsConstants.projectStatuses.PROJECT_STATUS_ID_DRAFT ||
          this.isPreProductionJobOnHold) &&
        !this.hasCalendarEvent
      )
    },

    isHoldButtonDisabled() {
      return (
        isEmpty(this.preProductionName) ||
        this.onHoldLoading ||
        (this.isPreProductionJobOnHold &&
          isEqual(this.defaultPayload, this.changedPayload))
      )
    },

    canHoldJob() {
      if (
        (!isNil(this.selectedPreProduction) && this.isPreProductionJobOnHold) ||
        !this.isEditType
      ) {
        return true
      }

      return false
    },

    isSaveWithoutEventBtnDisabled() {
      return this.$v.preProductionName.$invalid
    },

    hasCalendarEvent() {
      return this.selectedPreProduction && this.selectedPreProduction.with_event
    },

    disableActions() {
      return (
        this.isFormInvalid &&
        this.isHoldButtonDisabled &&
        this.isSaveWithoutEventBtnDisabled
      )
    },

    durationList() {
      return map(entries(DurationsConstants.durations), ([key, value]) => ({
        text: value,
        value: Number(key),
      }))
    },
  },

  watch: {
    isFormInvalid(val) {
      this.updateFormSaveBtnDisabled(val)
    },

    changedPayload(val) {
      this.updateFormSaveBtnDisabled(
        (isEqual(this.defaultPayload, val) &&
          this.selectedPreProduction?.status_id !==
            VmsConstants.orderJobStatuses.ORDER_JOB_ON_HOLD) ||
          this.isFormInvalid
      )
    },
  },

  mounted() {
    if (!isEmpty(this.selectedPreProduction) && this.isEditType) {
      this.initUpdateForm()
    }

    if (
      !isNil(this.preProductionLinkingJob) &&
      !isEmpty(this.selectedPreProductionType) &&
      this.preProductionNewFormOpen
    ) {
      this.initNewFormWithLinkingJob()
    }

    this.formProductionTypeIcon = this.selectedProductionTypeIcon()

    if (this.isFormInvalid) {
      this.updateFormSaveBtnDisabled(true)
    }

    this.initDefaultPayload()
  },

  methods: {
    ...mapActions({
      updateSelectedPreProductionType:
        'project/pre-production/updateSelectedPreProductionType',
      showAddNewPreProductionsForm:
        'project/pre-production/showAddNewPreProductionsForm',
      showEditPreProductionsForm:
        'project/pre-production/showEditPreProductionsForm',
      updateFormSaveBtnDisabled: 'project/updateFormSaveBtnDisabled',
      updatePreProductionJob: 'project/pre-production/updatePreProductionJob',
      updateSelectedPreProductionId:
        'project/pre-production/updateSelectedPreProductionId',
      createNewPreProductionJob:
        'project/pre-production/createNewPreProductionJob',
      updateFormSaveBtnLoading: 'project/updateFormSaveBtnLoading',
    }),

    savePreProduction(options) {
      const payload = {
        name: this.preProductionName,
        production_date: momentTz(this.productionDate, 'DD MMM YYYY').format(
          'YYYY-MM-DD'
        ),
        external_note: this.generalComments,
        duration: this.duration,
        ...options,
      }

      if (this.isEditType) {
        this.updatePreProductionJob({
          project_id: this.project.id,
          order_job_id: this.selectedPreProduction.order_job_id,
          includes: null,
          payload: payload,
        })
      } else {
        payload.product_id = this.selectedPreProductionType.product.id
        payload.product_element_id = this.selectedPreProductionType.id

        if (this.preProductionLinkingJob) {
          payload.order_job_id = this.preProductionLinkingJob
        }

        this.createNewPreProductionJob({
          project_id: this.project.id,
          includes: null,
          payload: payload,
        })
      }
    },

    cancelPreProduction() {
      const vm = this

      SweetAlert.fire({
        title: 'Warning!',
        html: 'Are you sure you want to cancel your changes?',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        heightAuto: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          if (vm.isEditType) {
            this.showEditPreProductionsForm({
              show: false,
            })
          } else {
            this.showAddNewPreProductionsForm(false)
            this.updateSelectedPreProductionType(null)
          }

          this.updateSelectedPreProductionId(null)
        }
      })
    },

    handleGeneralCommentsUpdated(comments) {
      this.generalComments = comments
    },

    initDefaultPayload() {
      this.defaultPayload = {
        preProductionName: this.preProductionName,
        productionDate: this.productionDate,
        duration: parseInt(this.duration),
        generalComments: this.generalComments,
      }
    },

    initUpdateForm() {
      this.preProductionName = this.selectedPreProduction.name
      this.productionDate = this.selectedPreProduction.production_date
        ? momentTz
            .unix(this.selectedPreProduction.production_date)
            .format('DD MMM YYYY')
        : null
      this.generalComments = isEmpty(this.selectedPreProduction.external_note)
        ? null
        : this.selectedPreProduction.external_note
      this.duration = this.selectedPreProduction.duration

      // Add auto scroll to top of selected pre-production edit form
      const preProdListElem = document.getElementById(
        `preProductionsList-${this.selectedPreProduction.order_job_id}`
      )

      if (preProdListElem) {
        const selectedPreProdTop = preProdListElem.getBoundingClientRect().top

        // 200 = is roughly edit form top - pre-production thumbnail, info and approve button
        const preProdContainerElem = document.getElementById(
          'preProductionsContainer'
        )

        if (preProdContainerElem) {
          preProdContainerElem.scrollTop = selectedPreProdTop - 200
        }
      }

      // run validation after setting the initial values
      this.$v.$touch()
    },

    initNewFormWithLinkingJob() {
      const selectedFilmingDetail = find(this.filmingDetails.data, {
        id: this.preProductionLinkingJob,
      })

      const selectedDelivery = find(this.deliveries.data, {
        id: this.preProductionLinkingJob,
      })

      const selectedJob = !isEmpty(selectedFilmingDetail)
        ? selectedFilmingDetail
        : selectedDelivery

      this.productionDate = selectedJob.production_date
        ? momentTz.unix(selectedJob.production_date).format('DD MMM YYYY')
        : null
      this.generalComments = isEmpty(selectedJob.external_note)
        ? ''
        : selectedJob.external_note
      this.duration = selectedJob.calendar_time
    },

    handleClickHoldButton() {
      this.onHoldLoading = true

      const productionDate = !isEmpty(this.productionDate)
        ? momentTz(this.productionDate, 'DD MMM YYYY').format('YYYY-MM-DD')
        : null

      const payload = {
        name: this.preProductionName,
        production_date: productionDate,
        external_note: this.generalComments,
        duration: this.duration,
        on_hold: 1,
      }

      if (this.isEditType) {
        this.updatePreProductionJob({
          project_id: this.project.id,
          order_job_id: this.selectedPreProduction.order_job_id,
          includes: null,
          payload: payload,
        })
      } else {
        payload.product_id = this.selectedPreProductionType.product.id
        payload.product_element_id = this.selectedPreProductionType.id

        if (this.preProductionLinkingJob) {
          payload.order_job_id = this.preProductionLinkingJob
        }

        this.createNewPreProductionJob({
          project_id: this.project.id,
          includes: null,
          payload: payload,
        })
      }
    },

    handleClickSaveWithoutEventBtn() {
      const payload = {
        with_event: 0,
        production_date: null,
        duration: null,
      }

      if (this.productionDate && this.duration) {
        payload.production_date = momentTz(
          this.productionDate,
          'DD MMM YYYY'
        ).format('YYYY-MM-DD')
        payload.duration = this.duration
      }

      if (this.preProductionLinkingJob) {
        payload.order_job_id = this.preProductionLinkingJob
      }

      this.savePreProduction(payload)
    },
  },
}
</script>
