<template>
  <v-app>
    <div
      :class="[
        'vms-job-changes-form',
        isMobileScreen
          ? 'vms-project__form--fullscreen vd-background-white'
          : 'vd-background-lightest-grey',
      ]"
    >
      <div class="vms-project__form--container">
        <div
          v-if="isMobileScreen"
          class="vms-project__form--header vd-display-flex vd-align-center vd-padding-20 vd-margin-bottom-20 vd-border-lightest-grey vd-background-white"
        >
          <span v-if="icon" :class="['vd-icon vd-margin-right-small', icon]" />

          {{ title }}

          <span
            v-if="!isLoading"
            class="vd-icon icon-xmark vd-cursor-pointer vd-darker-grey vd-hover-black vd-margin-left-auto vms-modal__close"
            @click="handleCancel"
          ></span>
        </div>

        <div
          :class="[
            'vms-project__form--body vd-border-white',
            isMobileScreen ? 'vd-padding-small' : 'vd-padding-medium',
          ]"
        >
          <div
            v-if="!isMobileScreen"
            class="vd-padding-small vd-display-flex vd-align-center vd-h4"
          >
            <span
              v-if="icon"
              :class="['vd-icon vd-margin-right-small', icon]"
            />
            {{ title }}

            <span
              v-if="isOrderJobChangeDone"
              class="vd-icon icon-xmark vd-cursor-pointer vd-darker-grey vd-hover-black vd-margin-left-auto"
              @click="handleCancel"
            ></span>
          </div>

          <default-loader
            v-if="isLoading"
            class="vd-margin-block-50"
          ></default-loader>

          <div
            v-else
            :class="[
              !isMobileScreen
                ? 'vd-display-flex vd-flex-wrap vd-margin-top-medium'
                : '',
            ]"
          >
            <div class="vms-job-changes-form__column vd-padding-medium">
              <div class="vd-margin-bottom-30">
                <div v-if="isOrderJobChangeDone" class="vd-field">
                  <label>Date</label>
                  <div
                    v-if="isOrderJobChangeDone"
                    class="vd-border-grey vd-border-width-0 vd-border-bottom-width-1"
                  >
                    {{ productionDate }}
                  </div>
                </div>

                <div
                  v-else
                  :class="[
                    'vd-field vd-required',
                    setInputValidationClass('productionDate'),
                  ]"
                >
                  <label class="vd-position-relative">Date</label>

                  <flat-pickr
                    id="changeDate"
                    v-model="productionDate"
                    :config="productionDateConfig"
                    placeholder="When will changes happen?"
                    name="productionDate"
                    :disabled="isLoading"
                    @input="handleInputUpdate($event, 'productionDate')"
                  >
                  </flat-pickr>
                </div>
              </div>

              <div class="vd-margin-bottom-30">
                <div v-if="isOrderJobChangeDone" class="vd-field">
                  <label>Start Time</label>
                  <div
                    v-if="isOrderJobChangeDone"
                    class="vd-border-grey vd-border-width-0 vd-border-bottom-width-1"
                  >
                    {{ productionTime }}
                  </div>
                </div>

                <form-time-picker
                  v-else
                  id="jobChangesProductionTime"
                  v-model="productionTime"
                  :selected-date="productionDate"
                  :class="[
                    'vd-field vd-required',
                    setInputValidationClass('productionTime'),
                  ]"
                  :disabled="isLoading"
                  label="Start Time"
                  placeholder="Select time"
                  @change="handleInputUpdate($event, 'productionTime')"
                ></form-time-picker>
              </div>

              <div class="vd-padding-bottom-30 vd-position-relative">
                <div v-if="isOrderJobChangeDone" class="vd-field">
                  <label for="" class="vd-position-relative"
                    >Time required</label
                  >
                  <div
                    class="vd-border-grey vd-border-width-0 vd-border-bottom-width-1"
                  >
                    {{ duration }}
                  </div>
                </div>

                <v-select
                  v-else
                  id="durationSelect"
                  v-model="duration"
                  placeholder="How long is needed?"
                  persistent-placeholder
                  :class="[
                    'vd-field vd-required ',
                    setInputValidationClass('duration'),
                  ]"
                  :items="durationList"
                  :disabled="isLoading"
                  :hide-selected="true"
                  :required="true"
                  :menu-props="menuProps"
                  label="Time required"
                  no-data-text="No results to display"
                  @change="handleInputUpdate($event, 'duration')"
                >
                </v-select>
              </div>

              <job-changes-team-members
                v-model="editor"
                :users-list="usersList"
                :assigned-user="assignedUser"
                :is-disabled="isLoading"
                :is-users-list-loading="isUsersListLoading"
                :is-order-job-change-done="isOrderJobChangeDone"
              ></job-changes-team-members>
            </div>

            <job-changes-attachments
              class="vms-job-changes-form__column vd-padding-medium"
              :attachment-api-url="attachmentApiUrl"
              :is-attachments-loading="isAttachmentsLoading"
              :is-order-job-change-done="isOrderJobChangeDone"
              :has-job-change="hasJobChange"
              :attachments="attachments"
              @click:add-file="handleAddFile"
              @click:delete-attachment="handleDeleteAttachment"
              @update:attachments="handleProcessFile"
            ></job-changes-attachments>

            <div class="vms-job-changes-form__column vd-padding-medium">
              <div v-if="isOrderJobChangeDone" class="vd-field">
                <label>Description</label>
                <!-- eslint-disable vue/no-v-html -->
                <div v-if="description" v-html="description"></div>
                <div
                  v-else
                  class="vd-full-width vd-padding-medium vd-padding-bottom-extra-large vd-background-lighter-grey vd-dark-grey vd-border-radius-6"
                >
                  No description
                </div>
              </div>

              <text-editor
                v-else
                label="Description"
                class="vd-field"
                :content="description"
                @update:text-editor-content="handleEditorUpdate"
              ></text-editor>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!isOrderJobChangeDone"
        :class="[
          'vms-project__form--footer vd-padding-medium',
          isMobileScreen ? 'vd-background-lightest-grey' : 'vd-border-white',
        ]"
      >
        <div class="vms-project__form--actions vd-text-align-right">
          <button
            v-if="!isLoading"
            class="vd-margin-right-40 vd-black vd-a"
            @click="handleCancel"
          >
            Cancel
          </button>

          <button
            class="vms-project__form--btn vd-btn-blue vd-btn-medium"
            :disabled="isSaveBtnDisabled"
            @click="handleSave"
          >
            {{ isLoading ? 'Loading...' : 'Save' }}
          </button>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import SweetAlert from '@plugins/sweet-alert'
import FormMixin from '@mixins/forms-mixin'
import { required } from 'vuelidate/lib/validators'
import momentTz from 'moment-timezone'
import FlatPickr from 'vue-flatpickr-component'
import FiltersMixin from '@mixins/filters-mixin'
import DurationsConstants from '@configs/all-durations'
import TextEditor from '@components/text-editor/text-editor'
import DefaultLoader from '@components/loaders/default-loader'
import JobChangesAttachments from '@views/project/components/forms/job-changes-form/components/job-changes-attachments'
import JobChangesTeamMembers from '@views/project/components/forms/job-changes-form/components/job-changes-team-members'
import Vuetify from 'vuetify'
import { entries, map, isEmpty } from 'lodash'
import FormTimePicker from '@components/form/form-time-picker'

export default {
  components: {
    DefaultLoader,
    FlatPickr,
    TextEditor,
    JobChangesAttachments,
    JobChangesTeamMembers,
    FormTimePicker,
  },

  mixins: [FormMixin, FiltersMixin],

  vuetify: new Vuetify(),

  props: {
    title: {
      type: String,
      required: false,
      default: 'Add Order Job Change',
    },

    icon: {
      type: String,
      required: false,
      default: '',
    },

    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    jobChange: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    attachmentApiUrl: {
      type: String,
      required: true,
      default: '',
    },

    isAttachmentsLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    usersList: {
      type: Array,
      required: false,
      default: () => [],
    },

    isUsersListLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      menuProps: {
        auto: true,
        closeOnClick: true,
        offsetY: true,
      },
      isFileUploadLoading: false,
      productionDate: '',
      productionTime: '',
      duration: '',
      editor: null,
      description: '',
    }
  },

  validations: {
    productionDate: {
      required,
    },

    productionTime: {
      required,
    },

    duration: {
      required,
      minValue: (value) => value >= 15,
    },
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
    }),

    hasJobChange() {
      return !isEmpty(this.jobChange)
    },

    productionDateConfig() {
      return {
        enableTime: false,
        allowInput: true,
        altFormat: 'd M Y',
        dateFormat: 'd M Y',
        altInput: true,
        disableMobile: 'true',
        minDate: 'today',
      }
    },

    isOrderJobChangeDone() {
      return this.jobChange?.is_done
    },

    isSaveBtnDisabled() {
      return (
        this.isLoading ||
        this.$v.$invalid ||
        this.isAttachmentsLoading ||
        this.isFileUploadLoading
      )
    },

    durationList() {
      return map(entries(DurationsConstants.durations), ([key, value]) => ({
        text: value,
        value: Number(key),
      }))
    },

    teamMembers() {
      return this.usersList.map((user) => ({
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
      }))
    },

    assignedUser() {
      return this.jobChange?.editor_user || {}
    },

    attachments() {
      return this.jobChange?.attachments || []
    },

    payload() {
      return {
        date: momentTz(this.productionDate, 'DD MMM YYYY').format('YYYY-MM-DD'),
        time: this.productionTime,
        time_required: this.duration,
        editor_user_id: this.editor,
        order_job_id: this.jobChange?.id,
        description: this.description,
        sort: 'desc',
        sortBy: 'created_at',
      }
    },
  },

  mounted() {
    this.setInitialValue()
  },

  methods: {
    setInitialValue() {
      if (this.hasJobChange) {
        if (this.jobChange.event) {
          this.productionDate = momentTz
            .unix(this.jobChange.event?.from)
            .format('DD MMM YYYY')
          this.productionTime = momentTz
            .unix(this.jobChange.event?.from)
            .format('HH:mm:ss')
        }
        this.duration = this.jobChange.time_required
        this.editor = this.jobChange.editor_user?.id
        this.description = this.jobChange.description

        this.$v.$touch()
      }
    },

    handleProcessFile() {
      this.$emit('update:attachments')
      this.isFileUploadLoading = false
    },

    handleAddFile() {
      this.isFileUploadLoading = true
    },

    handleDeleteAttachment(attachmentId) {
      this.$emit('click:delete-attachment', attachmentId)
    },

    handleEditorUpdate(description) {
      this.description = description
    },

    handleSave() {
      this.$emit('click:save', this.payload)
    },

    handleCancel() {
      if (this.isOrderJobChangeDone) {
        this.$emit('click:cancel')
      } else {
        SweetAlert.fire({
          title: 'Warning!',
          html: 'Are you sure you want to cancel your changes?',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
          confirmButtonColor: '#ff3333',
        }).then((result) => {
          if (result.isConfirmed) {
            this.$emit('click:cancel')
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@styles/plugins/vuetify';
@import '@styles/v2/views/project/components/forms/job-changes-form/job-changes-form.scss';
</style>
